export const formattedSize = {
  standard: "Standard",
  twin: "Twin",
  twin_xl: "Twin XL",
  twin_twin_xl: "Twin / Twin XL",
  full: "Full",
  full_queen: "Full / Queen",
  queen: "Queen",
  king: "King",
  split_king: "Split King",
  cal_king: "Cal King",
  king_cal_king: "King / Cal King",
  xs: "X Small",
  s: "Small",
  m: "Medium",
  l: "Large",
  xl: "X Large",
  xxl: "2X Large",
  xxxl: "3X Large",
  small: "Small",
  medium: "Medium",
  large: "Large",
};

export const formattedWeight = {
  light: "Light",
  medium: "Medium",
};
