import * as types from "../actions/types";

const initialState = {
  errorMessage: "",
};

export default (state = initialState, action) => {
  let actions = {
    [types.SET_ORDER_ERROR]: () => {
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    },
    [types.SET_STORE_RECEIPT]: () => {
      return {
        ...state,
        receipt: action.receipt,
      };
    },
    [types.CLEAR_RECEIPT]: () => {
      return initialState;
    },
  };

  if (actions[action.type]) {
    return actions[action.type]();
  }
  return state;
};
