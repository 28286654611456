import { actions } from "react-redux-form";
import { loadRegister } from "./register";

const loadRegisterWithStoreInfo = true;

export const insertLineItems = ({
  quantity,
  sku,
  price,
  deliveryOption,
}) => dispatch => {
  const lineItem = {
    sku,
    base: price,
    delivery_method: deliveryOption.name,
    delivery_options: [
      {
        name: deliveryOption.name,
        price: deliveryOption.price,
        price_per_order: deliveryOption.pricePerOrder,
      },
    ],
  };

  for (let i = 0; i < quantity; i++) {
    dispatch(actions.push("lineItems", lineItem));
  }
};

export const incrementLineItem = sku => {
  return (dispatch, getState) => {
    const { lineItems } = getState();
    const lineItem = lineItems.find(lineItem => sku === lineItem.sku);
    dispatch(actions.push("lineItems", lineItem));
    dispatch(loadRegister(loadRegisterWithStoreInfo));
  };
};

export const decrementLineItem = sku => {
  return (dispatch, getState) => {
    const { lineItems } = getState();
    const lineItem = lineItems.find(lineItem => sku === lineItem.sku);
    const index = lineItems.lastIndexOf(lineItem);
    dispatch(removeLineItem(index));
    dispatch(loadRegister(loadRegisterWithStoreInfo));
  };
};

export const removeAllLineItems = product => {
  return (dispatch, getState) => {
    const { lineItems } = getState();
    const newLineItems = lineItems.filter(
      lineItem => lineItem.sku !== product.sku
    );
    dispatch(updateLineItems(newLineItems));
    dispatch(loadRegister(loadRegisterWithStoreInfo));
  };
};

export const updateLineItems = lineItems => {
  return actions.change("lineItems", lineItems);
};

export const updateRequestedDeliveryDates = dates => {
  return actions.merge(
    "lineItems",
    dates.map(date => ({ requested_delivery_date: date }))
  );
};

export const updateDeliveryMethods = deliveryMethods => {
  return actions.merge(
    "lineItems",
    deliveryMethods.map(delivery_method => ({ delivery_method }))
  );
};

export const removeLineItem = index => {
  return actions.remove("lineItems", index);
};
