import { COLORS, FONTS } from "components/uikit/Theme";
import React from "react";
import styled, { css } from "styled-components";

const selected = props =>
  props.selected &&
  css`
    font-family: ${FONTS.medium};
    &:before {
      content: "";
      background-color: ${COLORS.graphite};
      height: calc(100% + 8px);
      width: calc(100% + 8px);
      position: absolute;
      top: -4px;
      left: -4px;
      z-index: -2;
    }
    &:after {
      content: "";
      background-color: white;
      height: calc(100% + 4px);
      width: calc(100% + 4px);
      position: absolute;
      top: -2px;
      left: -2px;
      z-index: -1;
    }
  `;

const disabled = props =>
  props.disabled &&
  css`
    color: ${COLORS.mediumGray};
    background: linear-gradient(
      to top left,
      ${COLORS.ultraLightGray} calc(50% - 1px),
      ${COLORS.mediumGray},
      ${COLORS.ultraLightGray} calc(50% + 1px)
    );
  `;

const Container = styled.div`
  display: flex;

  div:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const Button = styled.div`
  position: relative;
  background-color: ${COLORS.ultraLightGray};
  cursor: pointer;
  margin-bottom: 1rem;
  padding: 0.625rem 0;
  flex: 1;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1;
  color: ${COLORS.graphite};
  font-family: ${FONTS.regular};
  ${selected}
  ${disabled}
`;

const ButtonSelect = ({ options, selected, onChange }) => (
  <Container>
    {options.map(option => (
      <Button
        key={option.value}
        disabled={option.disabled}
        onClick={() => onChange(option.value)}
        selected={option.value === selected}
        data-testid="button-select-option"
      >
        {option.label}
      </Button>
    ))}
  </Container>
);
export default ButtonSelect;
