import * as Sentry from "@sentry/browser";
import { Body, Button, Header, Link } from "components/uikit";
import { sentryUrl } from "config";
import React from "react";
import styled from "styled-components";

if (sentryUrl) {
  Sentry.init({ dsn: sentryUrl });
}

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
`;

const Title = styled(Header.H1)`
  margin-bottom: 3rem;
`;

const Content = styled(Body.P)`
  margin-bottom: 3rem;
`;

const ReportButton = styled(Button)`
  margin-bottom: 1rem;
`;

const RefreshButton = styled(Link)`
  margin-bottom: 1rem;
`;

class SentryErrorBoundary extends React.Component {
  state = { hasError: false, eventId: null };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (sentryUrl) {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  refreshPage = () => {
    window.location = "/";
  };

  displayErrorModal = () => {
    const watchSentryModal = () => {
      const isModalVisible = () =>
        document.getElementsByClassName("sentry-error-embed-wrapper").length >
        0;
      if (isModalVisible()) {
        setInterval(() => {
          if (!isModalVisible()) {
            this.refreshPage();
          }
        }, 300);
      }
    };
    const { eventId } = this.state;
    Sentry.showReportDialog({ eventId, onLoad: watchSentryModal });
  };

  render() {
    const { hasError } = this.state;
    if (hasError && sentryUrl) {
      return (
        <ErrorContainer>
          <Title>It looks like we're having issues.</Title>
          <Content>
            If this is an emergency, contact #hero-digital. Otherwise, if you’d
            like, you can submit a crash report below.
          </Content>
          <ReportButton primary onClick={this.displayErrorModal}>
            Submit Crash Report
          </ReportButton>
          <RefreshButton secondary onClick={this.refreshPage}>
            Refresh Browser
          </RefreshButton>
        </ErrorContainer>
      );
    }
    return this.props.children;
  }
}

export default SentryErrorBoundary;
