import React from "react";

const Calendar = () => {
  return (
    <svg
      width="29px"
      height="31px"
      viewBox="0 0 29 31"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Calendar</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="768-Same-Day-Delivery-Modal"
          transform="translate(-244.000000, -200.000000)"
        >
          <rect fill="#FFFFFF" x="0" y="0" width="768" height="647"></rect>
          <g id="Group-3" transform="translate(172.000000, 128.000000)">
            <g id="Group" transform="translate(70.000000, 72.000000)">
              <g id="Calendar" transform="translate(2.000000, 0.000000)">
                <g transform="translate(0.000000, 0.000000)">
                  <path
                    d="M22.0820097,17.8366846 L17.5688847,17.8366846 L17.5688847,12.8933769 L22.0820097,12.8933769 L22.0820097,17.8366846 Z M22.0820097,23.7727872 L17.5688847,23.7727872 L17.5688847,18.8302744 L22.0820097,18.8302744 L22.0820097,23.7727872 Z M12.0488153,18.8302744 L16.5619403,18.8302744 L16.5619403,23.7727872 L12.0488153,23.7727872 L12.0488153,18.8302744 Z M6.52713472,18.8302744 L11.0418708,18.8302744 L11.0418708,23.7727872 L6.52713472,23.7727872 L6.52713472,18.8302744 Z M12.0488153,12.8933769 L16.5619403,12.8933769 L16.5619403,17.8366846 L12.0488153,17.8366846 L12.0488153,12.8933769 Z M6.52713472,12.8933769 L11.0418708,12.8933769 L11.0418708,17.8366846 L6.52713472,17.8366846 L6.52713472,12.8933769 Z M1.0066625,12.8933769 L5.52019028,12.8933769 L5.52019028,17.8366846 L1.0066625,17.8366846 L1.0066625,18.8302744 L5.52019028,18.8302744 L5.52019028,23.7727872 L1.0066625,23.7727872 L1.0066625,24.7663769 L5.52019028,24.7663769 L5.52019028,29.7084923 L6.52713472,29.7084923 L6.52713472,24.7663769 L11.0418708,24.7663769 L11.0418708,29.7084923 L12.0488153,29.7084923 L12.0488153,24.7663769 L16.5619403,24.7663769 L16.5619403,29.7084923 L17.5688847,29.7084923 L17.5688847,24.7663769 L22.0820097,24.7663769 L22.0820097,29.7084923 L23.0889542,29.7084923 L23.0889542,24.7663769 L27.6028847,24.7663769 L27.6028847,23.7727872 L23.0889542,23.7727872 L23.0889542,18.8302744 L27.6028847,18.8302744 L27.6028847,17.8366846 L23.0889542,17.8366846 L23.0889542,12.8933769 L27.6028847,12.8933769 L27.6028847,11.8997872 L1.0066625,11.8997872 L1.0066625,12.8933769 Z"
                    id="Fill-1"
                    fill="#60EDB3"
                  ></path>
                  <polygon
                    id="Fill-2"
                    fill="#808184"
                    points="6.52713472 3.46619744 22.0820097 3.46619744 22.0820097 2.47260769 6.52713472 2.47260769"
                  ></polygon>
                  <polygon
                    id="Fill-3"
                    fill="#808184"
                    points="23.0888333 3.46611795 27.6027639 3.46611795 27.6027639 11.9001051 27.6027639 12.8936949 27.6027639 17.8366051 27.6027639 18.8301949 27.6027639 23.7727077 27.6027639 24.7662974 27.6027639 29.7084128 23.0888333 29.7084128 22.0818889 29.7084128 17.5687639 29.7084128 16.5618194 29.7084128 12.0486944 29.7084128 11.04175 29.7084128 6.52701389 29.7084128 5.52006944 29.7084128 1.00694444 29.7084128 1.00694444 3.46611795 5.52006944 3.46611795 5.52006944 2.47252821 0 2.47252821 0 30.7020026 28.6097083 30.7020026 28.6097083 2.47252821 23.0888333 2.47252821"
                  ></polygon>
                  <polygon
                    id="Fill-4"
                    fill="#60EDB3"
                    points="22.0818889 5.93689744 23.0888333 5.93689744 23.0888333 0 22.0818889 0"
                  ></polygon>
                  <polygon
                    id="Fill-5"
                    fill="#60EDB3"
                    points="5.52002917 5.93689744 6.52697361 5.93689744 6.52697361 0 5.52002917 0"
                  ></polygon>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Calendar;
