import {
  ADD_FLASH_MESSAGE,
  CLEAR_FLASH_MESSAGE,
  CLEAR_ALL_FLASH_MESSAGES,
} from "actions/types";

const initialState = [];

const sameMessage = (msg1, msg2) =>
  msg1.type === msg2.type && msg1.text === msg2.text;

export default (state = initialState, action) => {
  switch (action.type) {
  case ADD_FLASH_MESSAGE:
    return [...state, action.message];
  case CLEAR_FLASH_MESSAGE:
    return state.filter(message => !sameMessage(message, action.message));
  case CLEAR_ALL_FLASH_MESSAGES:
    return initialState;
  default:
    return state;
  }
};
