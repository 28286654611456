import { ApolloProvider } from "@apollo/react-hooks";
import "@tuftandneedle/uikit/dist/css/tn_uikit.css";
import { client as axiosClient } from "api";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import App from "App";
import SentryErrorBoundary from "components/SentryErrorBoundary";
import { apiKey } from "config";
import { logout } from "helpers/login";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import rootReducer from "reducers";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f
  )
);

const customFetch = (url, options) => {
  const { body: data, method } = options;
  const { storeKey } = store.getState().storeInfo;
  let headers = {
    ...options.headers,
    "X-API-Key": apiKey,
    "X-SalesChannel": "retail",
    "content-type": "application/json",
    "accept": "application/json"
  };

  if (storeKey) {
    headers["X-RetailStoreKey"] = storeKey;
  }

  return axiosClient({ url, data, headers, method })
    .then(({ data, status, statusText }) => ({
      text: () => Promise.resolve(JSON.stringify(data)),
      ok: status >= 200 && status < 300,
      status,
      statusText,
    }))
    .catch(error => {
      if (
        error.response &&
        (error.response.data.errors || []).find(({ extensions }) =>
          ["authentication_failed", "authorization_failed"].includes(
            extensions.type
          )
        )
      ) {
        logout();
      } else {
        throw error;
      }
    });
};

const client = new ApolloClient({
  link: createHttpLink({
    fetch: customFetch,
    uri: "/api/graphql",
  }),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
    query: {
      fetchPolicy: "cache-and-network",
    },
  },
});

ReactDOM.render(
  <SentryErrorBoundary>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <App />
      </Provider>
    </ApolloProvider>
  </SentryErrorBoundary>,
  document.getElementById("root")
);
