import { classNames, light, hideOnPrint } from "components/uikit/utils";
import React from "react";
import styled from "styled-components";

const StyledLink = styled.a`
  ${hideOnPrint}
  ${light}
`;

const Link = ({
  className,
  small,
  large,
  secondary,
  primary,
  dark,
  ...props
}) => {
  const classes = classNames(className, {
    small,
    large,
    secondary,
    primary,
    dark,
  });
  return <StyledLink {...props} className={classes} />;
};

Link.defaultProps = {
  hideOnPrint: true,
};

export default Link;
