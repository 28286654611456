import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { closeModal } from "../../actions/modals";
import MintCheck from "../../svgs/MintCheck";

class ResetLinkSentModal extends Component {
  constructor(props) {
    super(props);
    this.handleCloseModalClick = this.handleCloseModalClick.bind(this);
  }

  handleCloseModalClick() {
    this.props.dispatch(closeModal());
  }

  render() {
    return (
      <div id="reset_link_sent_modal">
        <MintCheck />
        <h4>Password Reset Link Sent</h4>
        <p>Email sent to user&apos;s email on file</p>
        <Link
          className="underline_button"
          to="/"
          onClick={this.handleCloseModalClick}
        >
          Close
        </Link>
      </div>
    );
  }
}

export default connect()(ResetLinkSentModal);
