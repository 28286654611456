import { centToDollarNoDecimal } from "helpers/general";
import moment from "moment-business-days";
import React from "react";

const SameDayDelivery = ({
  deliveryPrice,
  estimatedDelivery,
  handleSameDayModalClick,
}) => {
  let deliveryDate = moment(estimatedDelivery).format("ddd, MMM D"),
    todaysDate = moment().format("ddd, MMM D");

  if (deliveryDate === todaysDate) deliveryDate = "Today";

  return (
    <div className="description">
      <p>Same Day Delivery - ${centToDollarNoDecimal(deliveryPrice)}</p>
      <p className="estimate">
        Get it {deliveryDate}{" "}
        <a onClick={handleSameDayModalClick} href="#/">
          Learn More
        </a>
      </p>
    </div>
  );
};

export default SameDayDelivery;
