import React, { Component } from "react";
import SourceSvg from "./SourceSvg";

class Sources extends Component {
  sizeByName(name) {
    return {
      tv: { width: "55", height: "40" },
      online: { width: "58", height: "32" },
      print: { width: "33", height: "40" },
      billboard: { width: "61", height: "40" },
      radio: { width: "54", height: "32" },
      coffee_cartel: { width: "25", height: "36" },
      amazon: { width: "39", height: "32" },
      friends: { width: "46", height: "36" },
      video: { width: "47", height: "36" },
      other: { width: "40", height: "40" },
      airbnb: { width: "38", height: "40" },
      "consumer-reports": { width: 119, height: 16 },
      digg: { width: 58, height: 32 },
      facebook: { width: 96, height: 19 },
      google: { width: 97, height: 32 },
      dwell: { width: 84, height: 32 },
      forbes: { width: 109, height: 28 },
      nyt: { width: 142, height: 20 },
      buzzfeed: { width: 112, height: 20 },
      vimeo: { width: 111, height: 32 },
      youtube: { width: 86, height: 36 },
    }[name];
  }

  sourceComponent(source) {
    let sizing = this.sizeByName(source.name);
    if (!sizing) {
      return this.sourceTextComponent(source);
    }
    return this.sourceIconComponent(source, sizing);
  }

  sourceIconComponent(source, sizing) {
    return (
      <li
        key={source.id || source.name}
        className={source.title ? "" : "icon_only"}
      >
        <a onClick={e => this.props.handleSourceClick(source, e)} href="#/">
          <SourceSvg name={source.name} {...sizing} />
          {source.title}
        </a>
      </li>
    );
  }

  sourceTextComponent(source) {
    return (
      <li key={source.id || source.name} className="text">
        <a onClick={e => this.props.handleSourceClick(source, e)} href="#/">
          <span>{source.title || source.name}</span>
        </a>
      </li>
    );
  }

  render() {
    return (
      <ul className="large_buttons">
        {this.props.sources.map(this.sourceComponent.bind(this))}
      </ul>
    );
  }
}

export default Sources;
