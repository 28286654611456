import { setStoreInfo } from "actions/auth";
import { applySpendHurdles } from "actions/promotions";
import Header from "components/Header";
import ResetPasswordForm from "components/ResetPasswordForm";
import Router from "components/Router";
import SendResetLinkForm from "components/SendResetLinkForm";
import CartContainer from "containers/CartContainer";
import CategoriesContainer from "containers/CategoriesContainer";
import CheckoutContainer from "containers/CheckoutContainer";
import LoginContainer from "containers/LoginContainer";
import ModalContainer from "containers/ModalContainer";
import ReceiptContainer from "containers/ReceiptContainer";
import SourceAttributionContainer from "containers/SourceAttributionContainer";
import { buildCart, cartSubtotal } from "helpers/cart";
import { getLoginData } from "helpers/login";
import React, { useEffect } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

export const AuthenticatedRoutes = ({ hasCart, hasReceipt }) => {
  return (
    <>
      <Route path="/" component={Header} />
      <Switch>
        <Route path="/products" component={CategoriesContainer} />
        <Route path="/cart" component={CartContainer} />
        <Route
          path="/checkout"
          render={props =>
            hasCart ? <CheckoutContainer {...props} /> : <Redirect to="/" />
          }
        />
        <Route
          path="/receipt"
          render={props =>
            hasReceipt ? <ReceiptContainer {...props} /> : <Redirect to="/" />
          }
        />
        <Route
          path="/source_attribution"
          render={props => <SourceAttributionContainer {...props} />}
        />
        <Route path="/" render={() => <Redirect to="/products/mattresses" />} />
      </Switch>
      <Route path="/" render={props => <ModalContainer {...props} />} />
    </>
  );
};
const UnauthenticatedRoutes = () => {
  return (
    <>
      <Route path="/" component={Header} />
      <Switch>
        <Route path="/login" render={props => <LoginContainer {...props} />} />
        <Route
          path="/reset_password"
          render={props => <SendResetLinkForm {...props} />}
        />
        <Route
          path="/retail/password_resets"
          render={props => <ResetPasswordForm {...props} />}
        />
        <Route path="/" render={() => <Redirect to="/login" />} />
      </Switch>
      <Route path="/" render={props => <ModalContainer {...props} />} />
    </>
  );
};

const App = ({ dispatch, storeKey, cart, receipt }) => {
  useEffect(() => {
    const data = getLoginData();
    data && dispatch(setStoreInfo(data));
  }, [dispatch]);

  const subtotal = cartSubtotal(cart);
  useEffect(() => {
    dispatch(applySpendHurdles());
  }, [subtotal, dispatch]);

  const hasCart = cart.length > 0;
  const hasReceipt = !!receipt;

  return (
    <Router>
      {storeKey && storeKey.length ? (
        <AuthenticatedRoutes hasCart={hasCart} hasReceipt={hasReceipt} />
      ) : (
        <UnauthenticatedRoutes />
      )}
    </Router>
  );
};

function mapStateToProps(state) {
  return {
    storeKey: state.storeInfo.storeKey,
    cart: buildCart(state),
    receipt: state.order.receipt,
  };
}

export default connect(mapStateToProps)(App);
