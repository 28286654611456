import {
  addFlashMessageAction,
  clearAllFlashMessagesAction,
  TYPES,
} from "actions/flashMessages";
import { closeModal, openModal } from "actions/modals";
import { completeCheckout } from "actions/order";
import { createStripeConnectionToken, createStripePaymentIntent } from "api";
import { Button } from "components/uikit";
import { stripeCardReaderSimulated } from "config";
import { MODAL_NAMES } from "containers/ModalContainer";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const useStripeTerminal = ({
  onSuccess,
  storeInfo,
  purchaseTransactionKey,
}) => {
  const dispatch = useDispatch();
  const [intentClientSecret, setIntentClientSecret] = useState();
  const [terminal, setTerminal] = useState();
  const [connected, setConnected] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);
  const [completed, setCompleted] = useState(false);
  const { storeKey, stripeLocationId } = storeInfo;

  const setModalMessage = useCallback(
    (message, cancelLabel, onCancel) => {
      dispatch(
        openModal(MODAL_NAMES.PROCESSING, { message, cancelLabel, onCancel })
      );
    },
    [dispatch]
  );

  const setFlashMessage = useCallback(
    flashMessage => {
      dispatch(addFlashMessageAction(flashMessage));
      dispatch(closeModal());
    },
    [dispatch]
  );

  useEffect(() => {
    const onFetchConnectionToken = () =>
      createStripeConnectionToken({ storeKey });
    const onUnexpectedReaderDisconnect = () => {
      setFlashMessage({
        type: TYPES.error,
        text: "Unexpected Card Reader disconnect",
      });
      setConnected(false);
    };

    const stripeTerminal = global.StripeTerminal.create({
      onFetchConnectionToken,
      onUnexpectedReaderDisconnect,
    });

    setTerminal(stripeTerminal);

    return () => stripeTerminal.disconnectReader();
  }, [storeKey, setFlashMessage]);

  useEffect(() => {
    if (!terminal || connected || attemptCount <= 0) return;

    const discoverReaders = () => {
      setModalMessage("Discovering Card Readers...");
      const config = {
        simulated: stripeCardReaderSimulated,
        location: stripeLocationId,
      };
      return terminal.discoverReaders(config);
    };

    const selectReader = ({ discoveredReaders, error }) =>
      new Promise((resolve, reject) => {
        const reader = discoveredReaders && discoveredReaders[0];
        if (error) {
          reject({
            type: TYPES.error,
            text: `Failed to discover: ${error.message}`,
          });
        } else if (!reader) {
          reject({ type: TYPES.error, text: "No available readers." });
        } else {
          resolve(reader);
        }
      });

    const connectReader = selectedReader =>
      new Promise((resolve, reject) => {
        setModalMessage(
          `Connecting to Card Reader: ${selectedReader.label}...`
        );

        terminal
          .connectReader(selectedReader, { fail_if_in_use: false })
          .then(({ error }) => {
            if (error) {
              reject({
                type: TYPES.error,
                text: `Failed to connect: ${error.message}`,
              });
            } else {
              resolve();
            }
          });
      });

    discoverReaders()
      .then(selectReader)
      .then(connectReader)
      .then(() => setConnected(true))
      .catch(setFlashMessage);
  }, [
    stripeLocationId,
    terminal,
    connected,
    attemptCount,
    setModalMessage,
    setFlashMessage,
  ]);

  useEffect(() => {
    if (!connected || intentClientSecret) return;

    const createPaymentIntent = () => {
      setModalMessage("Creating Payment Intent...");

      return createStripePaymentIntent({
        storeKey,
        purchaseTransactionKey,
      });
    };

    createPaymentIntent()
      .then(setIntentClientSecret)
      .catch(() =>
        setFlashMessage({
          type: TYPES.error,
          text: "Failed to create payment intent",
        })
      );
  }, [
    storeKey,
    purchaseTransactionKey,
    connected,
    intentClientSecret,
    attemptCount,
    setModalMessage,
    setFlashMessage,
  ]);

  useEffect(() => {
    if (!connected || !intentClientSecret) return;

    const collectPaymentMethod = () =>
      new Promise((resolve, reject) => {
        const message = "Collecting Payment. Please swipe your card...";
        const cancelLabel = "Cancel Card Reader";
        const onCancel = () => {
          terminal.cancelCollectPaymentMethod();
        };

        setModalMessage(message, cancelLabel, onCancel);

        terminal
          .collectPaymentMethod(intentClientSecret)
          .then(({ paymentIntent, error }) => {
            if (error) {
              if (error.code === "canceled") {
                reject({
                  type: TYPES.success,
                  text: "Payment successfully canceled.",
                });
              } else {
                reject({
                  type: TYPES.error,
                  text: `Failed to collect payment: ${error.message}`,
                });
              }
            } else {
              resolve(paymentIntent);
            }
          });
      });

    const processPayment = paymentIntent =>
      new Promise((resolve, reject) => {
        setModalMessage("Processing Payment...");

        terminal
          .processPayment(paymentIntent)
          .then(({ paymentIntent, error }) => {
            if (error) {
              reject({
                type: TYPES.error,
                text: `Failed to process payment: ${error.message}`,
              });
            } else if (!paymentIntent) {
              reject({
                type: TYPES.error,
                text:
                  "Failed to process payment: Connection problems => timeout",
              });
            } else {
              resolve(paymentIntent);
            }
          });
      });

    const completePayment = paymentIntent => {
      setModalMessage("Completing Payment...");

      return new Promise((resolve, reject) => {
        dispatch(completeCheckout(paymentIntent.id, "StripeCardReader"))
          .then(resolve)
          .catch(errorMessage =>
            reject({
              type: TYPES.error,
              text: errorMessage,
            })
          );
      });
    };

    collectPaymentMethod()
      .then(processPayment)
      .then(completePayment)
      .then(() => setCompleted(true))
      .catch(setFlashMessage);
  }, [
    dispatch,
    terminal,
    connected,
    intentClientSecret,
    attemptCount,
    setModalMessage,
    setFlashMessage,
  ]);

  useEffect(() => {
    if (completed) {
      dispatch(closeModal());
      onSuccess();
    }
  }, [onSuccess, dispatch, completed]);

  const startPaymentAttempt = () => {
    dispatch(clearAllFlashMessagesAction());
    dispatch(openModal(MODAL_NAMES.PROCESSING));
    setAttemptCount(count => count + 1);
  };

  return { attemptCount, startPaymentAttempt };
};

const StripeCardReader = ({ onSuccess, storeInfo, purchaseTransactionKey }) => {
  const { attemptCount, startPaymentAttempt } = useStripeTerminal({
    onSuccess,
    storeInfo,
    purchaseTransactionKey,
  });

  return (
    <div>
      <Button onClick={startPaymentAttempt}>
        {attemptCount === 0 ? "Charge with Card Reader" : "Retry Card Reader"}
      </Button>
    </div>
  );
};

export default StripeCardReader;
