import { openModal } from "actions/modals";
import { Header, Link } from "components/uikit";
import { ButtonSelect } from "components/uikit/Form";
import { MODAL_NAMES } from "containers/ModalContainer";
import { sizeLabel } from "helpers/products";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const Container = styled.div`
  margin: 1.5rem 0;
`;
const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const SizeSelector = ({
  sizes,
  selectedSize,
  setSelectedSize,
  selectableSizes,
  dimensionsMap,
}) => {
  const dispatch = useDispatch();
  const sizeOptions = sizes.map(size => ({
    value: size,
    label: sizeLabel(size),
    disabled: !selectableSizes.includes(size),
  }));

  const handleSizeChartClick = e => {
    e.preventDefault();
    dispatch(openModal(MODAL_NAMES.SIZE_CHART, { dimensionsMap }));
  };
  const showSizeChart = Object.values(dimensionsMap).find(dimension =>
    Object.values(dimension).find(value => value)
  );

  if (sizeOptions.length <= 1) return null;

  return (
    <Container data-testid="size-selector">
      <LabelContainer>
        <Header.H5>Size</Header.H5>
        {showSizeChart && (
          <Link secondary onClick={handleSizeChartClick}>
            Size Chart
          </Link>
        )}
      </LabelContainer>
      <ButtonSelect
        options={sizeOptions}
        selected={selectedSize}
        onChange={setSelectedSize}
      />
    </Container>
  );
};

export default SizeSelector;
