import React from "react";

const CircleMintCheck = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Group 11</title>
      <desc>Created with Sketch.</desc>
      <defs></defs>
      <g
        id="final_and_edgecases"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Final6-Copy" transform="translate(-52.000000, -121.000000)">
          <g id="Group-11" transform="translate(52.000000, 121.000000)">
            <circle
              id="Oval-49-Copy-22"
              fill="#60EDB3"
              cx="10"
              cy="10"
              r="10"
            ></circle>
            <polyline
              id="Stroke-3520-Copy-5"
              stroke="#FFFFFF"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              points="14 6 8.54347826 13 6 10.4565217"
            ></polyline>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CircleMintCheck;
