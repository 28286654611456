import { clearFlashMessageAction, TYPES } from "actions/flashMessages";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

const TYPES_CONFIG = {
  [TYPES.error]: "tn_flash_message_error",
  [TYPES.success]: "tn_flash_message_success",
};

const FlashMessages = () => {
  const dispatch = useDispatch();
  const flashMessages = useSelector(state => state.flashMessages);

  if (flashMessages.length <= 0) return null;

  return (
    <>
      {flashMessages.map(message => {
        const { type, text } = message;
        const typeClass = TYPES_CONFIG[type];
        return (
          <div
            className={`tn_flash_message ${typeClass}`}
            key={text}
            data-testid="flash-message"
          >
            {text}
            <span
              className="tn_flash_message_close"
              onClick={() => dispatch(clearFlashMessageAction(message))}
              data-testid="flash-message-close"
            ></span>
          </div>
        );
      })}
    </>
  );
};

export default FlashMessages;
