import { centToDollarNoDecimal } from "./general";
import { DELIVERY_METHODS } from "data/deliveryMethods";

export const formattedDeliveryMethod = (deliveryMethod, price) => {
  return `${DELIVERY_METHODS[deliveryMethod].name} - ${formattedPrice(price)}`;
};

export const formattedPrice = price => {
  let formattedPrice =
    typeof price === "string" ? `${price}` : `$${centToDollarNoDecimal(price)}`;

  if (formattedPrice === "$0") {
    formattedPrice = "Free";
  }

  return formattedPrice;
};
