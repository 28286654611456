import React from "react";
import { useSelector } from "react-redux";
import { Link } from "components/uikit";
import styled from "styled-components";
import { Header } from "components/uikit";

const ModalContainer = styled.div`
  max-width: 300px;
  min-width: 200px;
  min-height: 200px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const LoadingIcon = styled.div`
  position: relative;
  overflow: hidden;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #5febb0;
  &:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    content: "";
    background: #aff6d9;
    animation: "loading_light 4s infinite step-start";
  }
  &:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    content: "";
    background: #aff6d9;
    z-index: 1;
    background: #5febb0;
    animation: "loading_dark 4s infinite step-start";
  }
`;

const ProcessingModal = () => {
  const { message, onCancel, cancelLabel } = useSelector(
    state => state.modals.processing.payload
  );

  return (
    <ModalContainer>
      <Header.H4>{message || "Processing..."}</Header.H4>
      <LoadingIcon />
      {onCancel ? (
        <Link secondary onClick={onCancel}>
          {cancelLabel || "Cancel"}
        </Link>
      ) : (
        <div />
      )}
    </ModalContainer>
  );
};

export default ProcessingModal;
