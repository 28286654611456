import React, { Component } from "react";
import * as types from "../../actions/types";
import { connect } from "react-redux";
import { actions } from "react-redux-form";
import { clearAll } from "../../actions/order";
import { closeModal } from "../../actions/modals";

class ExitCheckoutModal extends Component {
  constructor(props) {
    super(props);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleKeepShoppingClick = this.handleKeepShoppingClick.bind(this);
  }

  handleCancelClick(e) {
    e.preventDefault();
    this.props.dispatch(clearAll());
  }

  handleKeepShoppingClick(e) {
    e.preventDefault();
    this.props.redirect("/");
    this.props.dispatch({ type: types.RESET_REGISTER });
    this.props.dispatch({ type: types.RESET_PROMOTION_KEYS });
    this.props.dispatch(actions.reset("payment"));
    this.props.dispatch(closeModal());
  }

  render() {
    return (
      <div id="exit_checkout">
        <h4>You sure you want to cancel?</h4>
        <p>
          Canceling this order deletes everything in the cart, and resets all
          forms.
        </p>
        <a
          className="primary_button"
          onClick={this.props.handleCloseModalClick}
          href="#/"
        >
          Do not cancel
        </a>
        <div className="flex_container">
          <a
            className="underline_button"
            onClick={this.handleCancelClick}
            href="#/"
          >
            Yes, cancel
          </a>
          <a
            className="underline_button"
            onClick={this.handleKeepShoppingClick}
            href="#/"
          >
            Keep shopping
          </a>
        </div>
      </div>
    );
  }
}

export default connect()(ExitCheckoutModal);
