// email regex from https://www.w3resource.com/javascript/form/email-validation.php
export const isValidEmail = email =>
  !!email && /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.(\w+([-.]\w+)*){2,}$/.test(email);

export const validateEmail = e => !e || isValidEmail(e);

export const validatePhone = phone => {
  if (!phone) {
    return true;
  }
  phone = phone || "";
  return phone.replace(/[^0-9]/g, "").replace(/^1+/, "").length === 10;
};

export const validateAddress = address => {
  if (!address) return true;
  return /\d/.test(address);
};

export const validateZip = zip => {
  if (!zip) {
    return true;
  }
  return /(^\d{5}$)|(^\d{5}-\d{4}$)|(^\d{9}$)/.test(zip);
};

export const addDashes = input => {
  if (!input) {
    return input;
  }
  input = input.replace(/\D/g, "").substring(0, 10);
  const size = input.length;

  if (size < 4) {
    input = "" + input;
  } else if (size < 7) {
    input = `(${input.substring(0, 3)}) ${input.substring(3, 6)}`;
  } else {
    input = `(${input.substring(0, 3)}) ${input.substring(
      3,
      6
    )}-${input.substring(6, 10)}`;
  }
  return input;
};

export const notEmpty = value => {
  return value && value.length;
};

export const isNotPOBox = value => {
  // PO Box without leading number
  return !(/\b(?:p(?:[o0]st(al)?)?[ .]*([o0](?:ffice)?)?[ .]*((?:b([o0]x|in)))?)(?=\s*#?\d)/i.test(value) ||
  // PO Box with leading number
  /\b(?=\s*#?\d*\s*)(?:p(?:[o0]st(al)?)?[ .]*([o0](?:ffice)?)?[ .]*((?:b([o0]x|in)))?)$/i.test(value));
};
