import { modelReducer, formReducer } from "react-redux-form";

const initialState = {
  store: "",
  email: "",
  password: "",
  resetPasswordEmail: "",
  resetToken: "",
  newPassword: "",
  newPasswordConfirm: "",
};

export const login = modelReducer("login", initialState);
export const loginForm = formReducer("login", initialState);
