import { SET_STORE_INFO } from "../actions/types";

const initialState = {};

const isValidStoreInfo = ({ store_key: storeKey, user = {} }) => {
  return storeKey && user.id;
};

export default (state = initialState, action) => {
  let actions = {
    [SET_STORE_INFO]: () => {
      const { storeInfo } = action;
      const { store_hours: storeHours, store_phone: storePhone, store_address: storeAddress } = storeInfo;

      if (isValidStoreInfo(storeInfo)) {
        return {
          storeKey: storeInfo.store_key,
          storeName: storeInfo.store_name,
          storeAddress,
          storeHours,
          storePhone,
          userId: storeInfo.user.id,
          userName: storeInfo.user.name,
          stripeLocationId: storeInfo.stripe_location_id,
        };
      } else {
        return state;
      }
    },
  };

  if (actions[action.type]) {
    return actions[action.type]();
  }
  return state;
};
