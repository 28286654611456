export const googleMapApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

const allowedHosts = [
  /^localhost$/,
  /^.+\.tuftandneedle\.now\.sh$/,
  /^.+\.tuftandneedle\.com$/,
];

export const enabledGoogleMaps = allowedHosts.some(reg =>
  window.location.hostname.match(reg)
);

export const apiKey = process.env.REACT_APP_API_KEY;

export const apiBaseUrl = process.env.REACT_APP_API_ROOT;

export const stripeCardReaderSimulated =
  process.env.REACT_APP_STRIPE_CARD_READER_SIMULATED === "true";

export const sentryUrl = process.env.REACT_APP_SENTRY_URL;
