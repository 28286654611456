import { STORE_PICKUP } from "data/deliveryMethods";
import { centToDollar } from "./general";

export const isAddressRequired = lineItems =>
  lineItems.some(({ delivery_method }) => delivery_method !== STORE_PICKUP);

export const buildCart = state => {
  const lineItems = state.lineItems || [];

  return lineItems.reduce((acc, { sku, base }) => {
    const item = acc.find(l => l.sku === sku);
    if (item) {
      item.quantity += 1;
    } else {
      acc.push({ sku, price: base, quantity: 1 });
    }

    return acc;
  }, []);
};

export const cartSubtotal = cart => {
  const subtotal = cart.reduce((num, lineItem) => {
    return num + lineItem.price * lineItem.quantity;
  }, 0);

  return centToDollar(subtotal);
};
