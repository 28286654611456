import React from "react";

const SourceSvg = props => {
  let { name, ...other } = props;
  return (
    <svg className={props.name} preserveAspectRatio="xMinYMin meet" {...other}>
      <use xlinkHref={`#icon-source-attribution-${name}`} />
    </svg>
  );
};

export default SourceSvg;
