export const STORE_PICKUP = "store_pickup";
export const STANDARD_GROUND = "standard_ground";
export const FREIGHT = "freight";
export const WHITE_GLOVE = "white_glove";
export const SAME_DAY = "same_day";

export const DELIVERY_METHODS = {
  [STORE_PICKUP]: { name: "Store Pick Up" },
  [STANDARD_GROUND]: { name: "Standard Delivery" },
  [FREIGHT]: { name: "Standard Delivery" },
  [WHITE_GLOVE]: { name: "White Glove Delivery" },
  [SAME_DAY]: { name: "Same Day Delivery" },
};
