import { sizeLabel } from "helpers/products";
import React from "react";
import { useSelector } from "react-redux";

const SizeChartModal = () => {
  const { dimensionsMap } = useSelector(
    state => state.modals.sizeChart.payload
  );
  const hasPhysical = Object.values(dimensionsMap).find(d => d.physical);
  const hasShipping = Object.values(dimensionsMap).find(d => d.shipping);
  const hasWeight = Object.values(dimensionsMap).find(d => d.weight);

  return (
    <div id="size_chart_content">
      <h3>Sizes and Dimensions</h3>
      <table>
        <thead>
          <tr>
            <th />
            {hasPhysical && <th>Dimensions</th>}
            {hasShipping && <th>Shipping Box Size</th>}
            {hasWeight && <th>Shipping Weight</th>}
          </tr>
        </thead>
        <tbody>
          {Object.entries(dimensionsMap).map(([size, dimension]) => (
            <tr key={size}>
              <td>{sizeLabel(size)}</td>
              {hasPhysical && <td>{dimension.physical}</td>}
              {hasShipping && <td>{dimension.shipping}</td>}
              {hasWeight && <td>{dimension.weight}</td>}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SizeChartModal;
