import React, { useEffect, useState } from "react";
import { Control } from "react-redux-form";
import { TextInput } from "components/uikit/Form";
import { enabledGoogleMaps } from "config";

const AddressLine1Input = ({ id, name, onAddressSelected }) => {
  const [address, setAddress] = useState();
  const [inputRef, setInputRef] = useState();

  const ensureNotObscuredByKeyboard = () => {
    window.scrollBy(0, 300);
  };

  useEffect(() => {
    if (address) {
      onAddressSelected(address);
      setAddress(null);
    }
  }, [address, onAddressSelected]);

  useEffect(() => {
    if (inputRef && enabledGoogleMaps) {
      const autocomplete = new global.google.maps.places.Autocomplete(
        inputRef,
        { types: ["address"] }
      );

      autocomplete.setFields(["address_component"]);

      const listener = autocomplete.addListener("place_changed", () => {
        const {
          address_components: addressComponents,
        } = autocomplete.getPlace();

        if (addressComponents) {
          const fields = addressComponents.reduce((acc, field) => {
            const { types, short_name: value } = field;
            types.forEach(type => (acc[type] = value));
            return acc;
          }, {});

          setAddress({
            line1: [fields.street_number, fields.route]
              .filter(value => value)
              .join(" "),
            city: fields.locality,
            state: fields.administrative_area_level_1,
            zip: fields.postal_code,
            country: fields.country,
          });
        }
      });

      return () => {
        global.google.maps.event.removeListener(listener);
        global.google.maps.event.clearInstanceListeners(autocomplete);
        Array.from(
          document.getElementsByClassName("pac-container")
        ).forEach(el => el.remove());
      };
    }
  }, [inputRef]);

  return (
    <Control.text
      getRef={setInputRef}
      model={`.${name}`}
      dynamic={false}
      id={id}
      name={name}
      placeholder=""
      onFocus={ensureNotObscuredByKeyboard}
      component={TextInput}
    />
  );
};

export default AddressLine1Input;
