import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useSelector } from "react-redux";

export const GET_PRODUCTS = gql`
  query GetProducts($skus: [String!]) {
    products: getRetailProducts(skus: $skus) {
      id
      name
      sku
    }
  }
`;

const useSelectedProducts = () => {
  const skus = [
    ...new Set(useSelector(state => state.lineItems.map(({ sku }) => sku))),
  ];
  const { data } = useQuery(GET_PRODUCTS, {
    variables: { skus },
  });
  const products = (data && data.products) || [];

  return products.reduce(
    (acc, product) => ({ ...acc, [product.sku]: product }),
    {}
  );
};

export default useSelectedProducts;
