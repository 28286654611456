import { modelReducer, formReducer } from "react-redux-form";

const initialState = {
  reasonId: "",
  email: "",
  reasonNote: "",
};

export const cancelOrder = modelReducer("cancelOrder", initialState);
export const cancelOrderForm = formReducer("cancelOrder", initialState);
