import {
  addFlashMessageAction,
  clearAllFlashMessagesAction,
  TYPES,
} from "actions/flashMessages";
import { closeModal, openModal } from "actions/modals";
import { completeCheckout } from "actions/order";
import { isAddressRequired } from "helpers/cart";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, Form } from "react-redux-form";
import CreditCardFields from "./CreditCardFields";

const elements = window.stripe.elements();

const style = {
  base: {
    color: "#32325d",
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: "antialiased",
    fontSize: "16px",
    "::placeholder": {
      color: "#aab7c4",
    },
  },
  invalid: {
    color: "#E60F2C",
    iconColor: "#E60F2C",
  },
};

const createStripeToken = (card, customerInfo, billingAddress) => {
  let stripeCustomerInfo;

  if (billingAddress.shippingMatchesBilling) {
    stripeCustomerInfo = {
      name: [customerInfo.firstname, customerInfo.lastname].join(" "),
      address_line1: customerInfo.line1,
      address_line2: customerInfo.line2,
      address_city: customerInfo.city,
      address_state: customerInfo.state,
      address_zip: customerInfo.zip,
      address_country: customerInfo.country,
    };
  } else {
    stripeCustomerInfo = {
      name: billingAddress.billingName,
      address_line1: billingAddress.billingLine1,
      address_line2: billingAddress.billingLine2,
      address_city: billingAddress.billingCity,
      address_state: billingAddress.billingState,
      address_zip: billingAddress.billingZip,
      address_country: billingAddress.billingCountry,
    };
  }

  return new Promise((resolve, reject) => {
    window.stripe.createToken(card, stripeCustomerInfo).then(response => {
      if (response.error) {
        reject(response.error.message);
      } else {
        resolve(response.token.id);
      }
    });
  });
};

const cardNumber = elements.create("cardNumber", { style });
const cardExpiry = elements.create("cardExpiry");
const cardCvc = elements.create("cardCvc", { style });

const PaymentForm = ({ nextStep }) => {
  const dispatch = useDispatch();
  const customerInfo = useSelector(state => state.customerInfo);
  const shippingMatchesBilling = useSelector(
    state => state.payment.shippingMatchesBilling
  );
  const lineItems = useSelector(state => state.lineItems);
  const isStorePickup = !isAddressRequired(lineItems);

  useEffect(() => {
    dispatch(actions.change("payment.shippingMatchesBilling", !isStorePickup));
  }, [dispatch, isStorePickup]);

  const setError = useCallback(
    text => {
      dispatch(
        addFlashMessageAction({
          type: TYPES.error,
          text: text || "We couldn't process your payment, please try again.",
        })
      );
      dispatch(closeModal());
    },
    [dispatch]
  );

  const completeOrder = stripeToken => {
    dispatch(completeCheckout(stripeToken, "CC"))
      .then(() => {
        dispatch(closeModal());
        nextStep();
      })
      .catch(message => {
        setError(message);
      });
  };

  const onFormSubmit = billingAddress => {
    dispatch(clearAllFlashMessagesAction());
    dispatch(openModal("processing"));
    createStripeToken(cardNumber, customerInfo, billingAddress)
      .then(stripeToken => {
        completeOrder(stripeToken);
      })
      .catch(message => {
        setError(message);
      });
  };

  const billingNotEmpty = value =>
    shippingMatchesBilling ? true : value && value.length;

  return (
    <Form
      model="payment"
      autoComplete="off"
      onSubmit={onFormSubmit}
      validateOn="submit"
      validators={{
        billingName: { required: billingNotEmpty },
        billingLine1: { required: billingNotEmpty },
        billingCity: { required: billingNotEmpty },
        billingState: { required: billingNotEmpty },
        billingZip: { required: billingNotEmpty },
      }}
    >
      <fieldset>
        <CreditCardFields
          shippingMatchesBilling={shippingMatchesBilling}
          showShippingMatchesBilling={!isStorePickup}
          cardNumber={cardNumber}
          cardExpiry={cardExpiry}
          cardCvc={cardCvc}
        />
      </fieldset>
    </Form>
  );
};

export default PaymentForm;
