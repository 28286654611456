import * as types from "./types";

export const setError = error => {
  return {
    type: types.SET_ERROR,
    error,
  };
};

export const resetErrors = () => ({
  type: types.RESET_ERRORS,
});
