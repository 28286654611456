import { actions } from "react-redux-form";
import * as API from "../api";
import { resetErrors } from "./errors";
import { closeModal, openModal } from "./modals";
import * as types from "./types";

export const completeCheckout = (paymentToken, paymentMethod) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const order = {
        purchase_transaction_key: getState().register.purchase_transaction_key,
        payment_requests: [
          {
            payment_token: paymentToken,
            payment_method: paymentMethod,
          },
        ],
        user_id: getState().storeInfo.userId,
      };
      const connectionErrorMsg =
        "No response received from the server. Please check your internet connection.";
      const otherErrorMsg =
        "We're sorry, but we are unable to process your order.";

      API.placeOrder(order, getState().storeInfo.storeKey)
        .then(({ data }) => {
          dispatch(setStoreReceipt(data));
          resolve();
        })
        .catch(error => {
          let errorMessage;
          if (error.response.data.errors) {
            errorMessage = error.response.data.errors;
          } else if (error.request) {
            errorMessage = connectionErrorMsg;
          } else if (error.message) {
            errorMessage = error.message;
          } else {
            errorMessage = otherErrorMsg;
          }
          dispatch(setOrderError(errorMessage));
          reject(errorMessage);
        });
    });
  };
};

const setOrderError = errorMessage => {
  return {
    type: types.SET_ORDER_ERROR,
    errorMessage,
  };
};

const setStoreReceipt = receipt => {
  return {
    type: types.SET_STORE_RECEIPT,
    receipt,
  };
};

export const cancelOrder = () => {
  return (dispatch, getState) => {
    let data = {
      slug: getState().cancelOrder.slug,
      reason_id: getState().cancelOrder.reasonId,
      email: getState().cancelOrder.email,
      reason_note: getState().cancelOrder.reasonNote,
    };

    dispatch(openModal("processing"));
    API.cancelOrder(data)
      .then(() => {
        dispatch(closeModal());
        dispatch(clearReceipt());
      })
      .catch(() => {
        dispatch(openModal("cancelOrder"));
        dispatch(actions.setErrors("cancelOrder", { processible: false }));
      });
  };
};

export const clearReceipt = () => {
  return dispatch => {
    dispatch({ type: types.CLEAR_RECEIPT });
    dispatch(actions.reset("cancelOrder"));
  };
};

export const clearAll = () => {
  return dispatch => {
    dispatch({ type: types.START_OVER });
    dispatch(closeModal());
    dispatch(actions.reset("customerInfo"));
    dispatch(actions.reset("lineItems"));
    dispatch(actions.reset("payment"));
    dispatch({ type: types.RESET_PROMOTION_KEYS });
    dispatch(resetErrors());
  };
};
