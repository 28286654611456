import {
  decrementLineItem,
  incrementLineItem,
  removeAllLineItems,
} from "actions/lineItems";
import QuantitySelector from "components/QuantitySelector";
import { Link } from "components/uikit";
import { productImage } from "helpers/products";
import useSelectedProducts from "hooks/useSelectedProducts";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const NameCell = styled.div`
  display: flex;
  align-items: center;
  a {
    margin-left: auto;
  }
`;

const CartItems = ({ cart }) => {
  const dispatch = useDispatch();
  const products = useSelectedProducts();

  const handleRemoveClick = (sku, event) => {
    event.preventDefault();
    dispatch(removeAllLineItems({ sku }));
  };

  return (
    <table>
      <thead>
        <tr>
          <th>Item</th>
          <th>Qty</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        {cart.map(lineItem => {
          const product = products[lineItem.sku];

          return (
            <tr key={lineItem.sku}>
              <td>
                <NameCell>
                  <img src={productImage(lineItem)} alt={lineItem.sku} />
                  {product && product.name}
                  <Link
                    secondary
                    onClick={e => handleRemoveClick(lineItem.sku, e)}
                  >
                    Remove
                  </Link>
                </NameCell>
              </td>
              <td>
                <QuantitySelector
                  quantity={lineItem.quantity}
                  decrement={() => dispatch(decrementLineItem(lineItem.sku))}
                  increment={() => dispatch(incrementLineItem(lineItem.sku))}
                />
              </td>
              <td>${lineItem.quantity * (lineItem.price / 100)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default CartItems;
