import { openModal } from "actions/modals";
import { clearAll, clearReceipt } from "actions/order";
import { loadSourceAttributions } from "actions/sourceAttribution";
import { Button, Header } from "components/uikit";
import {
  SAME_DAY,
  STANDARD_GROUND,
  STORE_PICKUP,
  WHITE_GLOVE,
} from "data/deliveryMethods";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import DeliveryMethodItems from "./DeliveryMethodItems";
import OrderSummary from "./OrderSummary";
import ReceiptActions from "./ReceiptActions";

const DELIVERY_METHODS = [
  {
    key: STANDARD_GROUND,
    name: "Standard Delivery",
    additionalInfo:
      "We will email you with tracking info on each item once your order ships.",
  },
  {
    key: WHITE_GLOVE,
    name: "White Glove Delivery",
    additionalInfo:
      "XPO Logistics will reach out to confirm your delivery date and to provide a delivery window.",
  },
  {
    key: SAME_DAY,
    name: "Same Day Delivery",
  },
  {
    key: STORE_PICKUP,
    name: "Store Pick Up",
  },
];

const buildCustomer = receipt => {
  return {
    firstName: receipt.name.split(" ")[0],
  };
};

const buildDeliveryMethods = ({ shipments, delivery_service_fees }) => {
  const keys = DELIVERY_METHODS.map(item => item.key);
  const groupedDeliveryMethods = shipments.reduce((acc, shipment) => {
    // default to `standard_ground` in case there's a new delivery method not considered by the frontend yet.
    // An example of missing delivery_method is `freight` that's being used by just a few products and
    // we display grouped by Standard Delivery.
    const method = keys.includes(shipment.delivery_method)
      ? shipment.delivery_method
      : STANDARD_GROUND;
    const existingItems = acc[method] ? acc[method].items : [];
    const items = [...existingItems, ...shipment.items];
    const deliveryServiceFee = delivery_service_fees.find(
      fee => fee.delivery_method === method
    );
    const shippingCost = deliveryServiceFee
      ? deliveryServiceFee.shipping_cost
      : items.reduce((sum, item) => sum + item.shipping_cost, 0);

    acc[method] = { items, shippingCost };
    return acc;
  }, {});

  // map DELIVERY_METHODS to make sure the methods are being displayed in the right order
  return DELIVERY_METHODS.map(deliveryMethod => {
    return { ...deliveryMethod, ...groupedDeliveryMethods[deliveryMethod.key] };
  });
};

const buildOrderSummary = receipt => {
  const items = receipt.shipments.reduce(
    (acc, item) => [...acc, ...item.items],
    []
  );

  return {
    shippingCost: receipt.shipping_cost,
    recyclingFeeAmount: receipt.recycling_fee_amount,
    chargedTaxAmount: receipt.charged_tax_amount,
    orderTotal: receipt.order_total,
    receiptSubtotal: items.reduce((acc, item) => acc + item.cost, 0),
    receiptDiscount: items.reduce((acc, item) => acc + (item.discount || 0), 0),
  };
};

const Container = styled.div`
  margin: 0 4rem;
`;

const ReceiptHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
`;

const NewOrderButton = styled(Button)`
  display: block;
  margin: 1.2rem auto 4rem auto;
`;

const ThanksHeader = styled(Header.H5Uppercase)`
  margin-bottom: 0.5rem;
`;

const ReceiptContainer = ({ history }) => {
  const dispatch = useDispatch();
  const receipt = useSelector(state => state.order.receipt);
  const sourceAttributions = useSelector(state => state.sourceAttributions);
  const deliveryMethods = buildDeliveryMethods(receipt);
  const orderSummary = buildOrderSummary(receipt);
  const customer = buildCustomer(receipt);

  const processedAt = () => {
    return moment(receipt.processed_at).format("ddd, MMM D");
  };

  const attributionSource = () => {
    let sa = sourceAttributions;
    let source = sa && sa.sources.find(s => s.id === sa.savedCategoryId);
    if (!source) {
      return "Not Provided";
    }

    let sourceName = source.title;
    if (sa.savedSubcategoryName) {
      sourceName += `: ${sa.savedSubcategoryName}`;
    }
    return sourceName;
  };

  const handleCancelClick = e => {
    e.preventDefault();
    dispatch(openModal("cancelOrder"));
  };

  const handleSourceAttributionClick = e => {
    e.preventDefault();
    dispatch(loadSourceAttributions()).then(() => {
      history.push("/source_attribution");
    });
  };

  const handleNewOrderClick = e => {
    e.preventDefault();
    dispatch(clearReceipt());
  };

  useEffect(() => {
    return () => {
      dispatch(clearAll());
    };
  }, [dispatch]);

  return (
    <Container>
      <ReceiptHeader data-testid="receipt-header">
        <ThanksHeader>Thank you for your order!</ThanksHeader>
        <Header.H5>
          Order #{receipt.number} for {customer.firstName} was placed on{" "}
          {processedAt()}
        </Header.H5>
      </ReceiptHeader>
      {deliveryMethods.map(deliveryMethod => (
        <DeliveryMethodItems
          key={deliveryMethod.name}
          deliveryMethod={deliveryMethod}
        />
      ))}
      <OrderSummary orderSummary={orderSummary} />
      <ReceiptActions
        receipt={receipt}
        handleCancelClick={handleCancelClick}
        handleSourceAttributionClick={handleSourceAttributionClick}
        selectedSourceName={attributionSource()}
      />
      <NewOrderButton
        data-testid="new-order-button"
        onClick={handleNewOrderClick}
      >
        Start new order
      </NewOrderButton>
    </Container>
  );
};

export default ReceiptContainer;
