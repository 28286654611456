import { removeLineItem } from "actions/lineItems";
import { closeModal, openModal } from "actions/modals";
import { loadRegister } from "actions/register";
import { WHITE_GLOVE } from "data/deliveryMethods";
import { formattedDeliveryMethod } from "helpers/delivery";
import { centToDollar, centToDollarNoDecimal } from "helpers/general";
import { productImage } from "helpers/products";
import useSelectedProducts from "hooks/useSelectedProducts";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

const LineItemPrice = props => {
  const { discountPromo, discountPrice, originalPrice } = props;

  if (discountPromo && originalPrice > discountPrice) {
    return (
      <td>
         {discountPromo.name}<span className="original_price">${originalPrice}</span>${discountPrice}
      </td>
    );
  } else {
    return <td>{originalPrice === 0 ? "FREE" : `$${originalPrice}`}</td>;
  }
};

const ReviewLineItems = () => {
  const dispatch = useDispatch();
  const lineItems = useSelector(state => state.lineItems);
  const products = useSelectedProducts();

  const firstWhiteGloveIndex = lineItems.findIndex(
    ({ delivery_method }) => delivery_method === WHITE_GLOVE
  );

  const handleRemoveClick = (event, sku, index) => {
    event.preventDefault();
    if (lineItems.length === 1) {
      dispatch(openModal("exitCheckout"));
      return;
    }

    dispatch(openModal("processing"));
    dispatch(removeLineItem(index));
    dispatch(loadRegister()).then(() => {
      dispatch(closeModal());
    });
  };

  const renderLineItem = (registerItem, index) => {
    const product = products[registerItem.sku];
    if (!product) return null;
    const imgFilePath = productImage(registerItem);
    const deliveryOptionObj = registerItem.delivery_options.find(obj => {
      return obj.name === registerItem.delivery_method;
    });
    // store pickup has no delivery options, so we set a default of 0
    let deliveryOptionPrice = deliveryOptionObj ? deliveryOptionObj.price : 0;

    if (registerItem.delivery_method === WHITE_GLOVE) {
      if (index === firstWhiteGloveIndex) {
        deliveryOptionPrice = deliveryOptionObj.price_per_order;
      } else {
        deliveryOptionPrice = "Included";
      }
    }

    const originalPrice =
      registerItem.quantity * centToDollarNoDecimal(registerItem.base);
    const discountPromo = registerItem.promotion_discount
      ? registerItem.promotion_discount.find(promo => promo.amount > 0)
      : null;
    const discountPrice = discountPromo
      ? centToDollar(
          (registerItem.base - discountPromo.amount) * registerItem.quantity
        )
      : null;

    return (
      <tr key={index} data-testid="line-item">
        <td>
          {originalPrice !== 0 ? (
            <a
              onClick={e => handleRemoveClick(e, registerItem.sku, index)}
              className="remove"
              href="#/"
            >
              Remove
            </a>
          ) : (
            ""
          )}
        </td>
        <td>
          <img src={imgFilePath} alt="" />
          <div>
            <p>{product.name}</p>
            <p>
              {formattedDeliveryMethod(
                registerItem.delivery_method,
                deliveryOptionPrice
              )}
            </p>
          </div>
        </td>
        <td>
          <span>{registerItem.quantity}</span>
        </td>
        <LineItemPrice
          discountPromo={discountPromo}
          discountPrice={discountPrice}
          originalPrice={originalPrice}
        ></LineItemPrice>
      </tr>
    );
  };

  return (
    <table>
      <thead>
        <tr>
          <th></th>
          <th>Item</th>
          <th>Qty</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>{lineItems.map(renderLineItem)}</tbody>
    </table>
  );
};

export default ReviewLineItems;
