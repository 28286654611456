import { center } from "components/uikit/utils";
import styled from "styled-components";

export const H1DisplayShort = styled.h1.attrs({ className: "display short" })`
  ${center}
`;

export const H1Display = styled.h1.attrs({ className: "display" })`
  ${center}
`;

export const H1 = styled.h1`
  ${center}
`;

export const H2 = styled.h2`
  ${center}
`;

export const H2Display = styled.h2.attrs({ className: "display" })`
  ${center}
`;

export const H3 = styled.h3`
  ${center}
`;

export const H4 = styled.h4`
  ${center}
`;

export const H4Display = styled.h4.attrs({ className: "display" })`
  ${center}
`;

export const H5 = styled.h5`
  ${center}
`;

export const H5Uppercase = styled.h5.attrs({ className: "uppercase" })`
  ${center}
`;

export const PDisplay = styled.p.attrs({ className: "display" })`
  ${center}
`;
