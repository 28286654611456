import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import React from "react";
import Select from "react-select";
import styled from "styled-components";

export const GET_PROMOTIONS = gql`
  query GetPromotions {
    promotions: getPromotions(salesChannels: ["retail"]) {
      label: name
      value: promotionCode
    }
  }
`;

const Container = styled.div`
  width: 380px;
`;

const Promotions = ({ handleSelectPromoChange }) => {
  const { data, loading } = useQuery(GET_PROMOTIONS, {
    fetchPolicy: "cache-and-network",
  });

  return (
    <Container>
      <Select
        defaultValue={[]}
        isMulti
        id="promotionKeys"
        options={data && data.promotions}
        onChange={handleSelectPromoChange}
        openOnFocus={true}
        classNamePrefix="promo-select"
        placeholder={loading ? "Loading..." : "Select..."}
      />
    </Container>
  );
};

export default Promotions;
