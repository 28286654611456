import { resetErrors } from "actions/errors";
import Breadcrumb from "components/checkout/Breadcrumb";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import CustomerInfoContainer from "./CustomerInfoContainer";
import DeliveryContainer from "./DeliveryContainer";
import PaymentContainer from "./PaymentContainer";
import ReviewContainer from "./ReviewContainer";

const CheckoutContainer = ({ location }) => {
  const dispatch = useDispatch();
  const { pathname } = location;

  useEffect(() => {
    dispatch(resetErrors());
  }, [dispatch, pathname]);

  return (
    <main>
      <Route path="/checkout" component={Breadcrumb} />
      <Switch>
        <Route
          path="/checkout/customer_info"
          component={CustomerInfoContainer}
        />
        <Route path="/checkout/delivery" component={DeliveryContainer} />
        <Route path="/checkout/review" component={ReviewContainer} />
        <Route
          path="/checkout/payment"
          render={props => <PaymentContainer {...props} />}
        />
      </Switch>
    </main>
  );
};

export default CheckoutContainer;
