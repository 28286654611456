import { insertLineItems } from "actions/lineItems";
import QuantitySelector from "components/QuantitySelector";
import { Button, Header, Theme } from "components/uikit";
import { formatMoney } from "helpers/general";
import { buildDimensionsMap, productImage } from "helpers/products";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { ReactComponent as CloseSvgIcon } from "svgs/closeCircleFill.svg";
import { ReactComponent as PlusSvgIcon } from "svgs/plusCircle.svg";
import ColorSelector from "./productSelection/ColorSelector";
import DeliveryOptionSelector from "./productSelection/DeliveryOptionSelector";
import SizeSelector from "./productSelection/SizeSelector";
import WeightSelector from "./productSelection/WeightSelector";
import AddonSelector from "./productSelection/AddonSelector";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProductHeader = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1rem;
  cursor: pointer;
`;

const Content = styled.div`
  margin: 0 7rem;
`;

const Image = styled.img`
  width: 5rem;
  height: 5rem;
  margin-right: 1rem;
`;

const Title = styled(Header.H4)`
  flex-grow: 1;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2rem;

  > * {
    margin-right: 1rem;
  }
`;

const ActionButton = styled(Button)`
  width: 20rem;
`;

const getMiddleItem = array => array[Math.ceil(array.length / 2) - 1];

const ProductFamily = ({ productFamily }) => {
  const dispatch = useDispatch();
  const products = productFamily.products;
  const defaultAddon = "base";

  const colors = [...new Set(products.map(({ color }) => color))];
  const weights = [...new Set(products.map(({ weight }) => weight))];
  const sizes = [...new Set(productFamily.products.map(({ size }) => size))];
  const dimensionsMap = buildDimensionsMap({ products });

  const [open, setOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [selectedSize, setSelectedSize] = useState(products[0].size);
  const [selectedColor, setSelectedColor] = useState(getMiddleItem(colors));
  const [selectedWeight, setSelectedWeight] = useState(products[0].weight);
  const [selectedAddon, setSelectedAddon] = useState(defaultAddon);
  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState();
  const availableProducts = products.filter(({ outOfStock }) => !outOfStock);

  const selectableSizes = availableProducts
    .filter(({ color }) => color === selectedColor)
    .filter(({ weight }) => weight === selectedWeight)
    .map(({ size }) => size);
  const selectableColors = availableProducts
    .filter(({ size }) => size === selectedSize)
    .filter(({ weight }) => weight === selectedWeight)
    .map(({ color }) => color);
  const selectableWeights = availableProducts
    .filter(({ size }) => size === selectedSize)
    .filter(({ color }) => color === selectedColor)
    .map(({ weight }) => weight);

  const getCurrentProduct = products => {
    const filtered = products
      .filter(({ size }) => size === selectedSize)
      .filter(({ color }) => color === selectedColor)
      .filter(({ weight }) => weight === selectedWeight);
    if (filtered[0].addons === "{}") {
      return filtered[0];
    } else {
      return filtered.filter(({ addons }) => addons.includes(selectedAddon))[0];
    }
  };
  const selectedProduct = getCurrentProduct(products);
  const formattedSinglePrice = formatMoney(selectedProduct.originalPrice);
  const formattedPrice = formatMoney(quantity * selectedProduct.originalPrice);
  const availableForDelivery =
    selectedDeliveryOption && selectedDeliveryOption.available;

  const addToCart = () => {
    dispatch(
      insertLineItems({
        quantity,
        sku: selectedProduct.sku,
        price: selectedProduct.originalPrice,
        deliveryOption: selectedDeliveryOption,
      })
    );
    setQuantity(1);
  };

  return (
    <Container data-testid="product-family">
      <ProductHeader onClick={() => setOpen(value => !value)}>
        <Image src={productImage(selectedProduct)} />
        <Title data-testid="product-family-name">
          {productFamily.fullName}
        </Title>
        {!open && <PlusSvgIcon fill={Theme.COLORS.graphite} />}
        {open && <CloseSvgIcon fill={Theme.COLORS.graphite} />}
      </ProductHeader>
      {open && (
        <Content data-testid="product-family-content">
          <Header.H4 data-testid="product-price">{formattedSinglePrice}</Header.H4>
          <SizeSelector
            sizes={sizes}
            selectedSize={selectedSize}
            setSelectedSize={setSelectedSize}
            selectableSizes={selectableSizes}
            dimensionsMap={dimensionsMap}
          />
          <ColorSelector
            colors={colors}
            productFamily={productFamily.fullName}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
            selectableColors={selectableColors}
          />
          {selectedProduct.addons !== "{}" && (
            <AddonSelector
              selectedSize={selectedSize}
              setSelectedAddon={setSelectedAddon}
              defaultAddon={defaultAddon}
              products={products}
            />
          )}

          <WeightSelector
            weights={weights}
            selectedWeight={selectedWeight}
            setSelectedWeight={setSelectedWeight}
            selectableWeights={selectableWeights}
          />
          <DeliveryOptionSelector
            product={selectedProduct}
            selectedDeliveryOption={selectedDeliveryOption}
            setSelectedDeliveryOption={setSelectedDeliveryOption}
          />
          <ActionContainer>
            {selectedProduct.outOfStock && (
              <ActionButton
                disabled
                secondary
                data-testid="product-family-sold-out"
              >
                Sold out
              </ActionButton>
            )}
            {!selectedProduct.outOfStock && (
              <>
                <QuantitySelector
                  quantity={quantity}
                  decrement={() => setQuantity(value => value - 1)}
                  increment={() => setQuantity(value => value + 1)}
                />
                <ActionButton
                  disabled={!availableForDelivery}
                  onClick={addToCart}
                  data-testid="product-family-add-cart"
                >
                  {formattedPrice} - Add to cart
                </ActionButton>
              </>
            )}
          </ActionContainer>
        </Content>
      )}
    </Container>
  );
};

export default ProductFamily;
