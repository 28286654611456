const LOGIN_KEY = "store_info";

export const setLoginData = data =>
  localStorage.setItem(LOGIN_KEY, JSON.stringify(data));

export const getLoginData = () =>
  localStorage.getItem(LOGIN_KEY) &&
  JSON.parse(localStorage.getItem(LOGIN_KEY));

export const logout = () => {
  localStorage.removeItem(LOGIN_KEY);
  window.location.reload();
};
