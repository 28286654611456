import React from "react";
import classes from "classnames";

const AccessibleTooltip = ({
  tooltipId,
  message,
  contentCustomClass,
  label = "?",
  ariaLabel = "More info",
}) => {
  const displayTooltip = (tooltipButton, show) => {
    const $button = tooltipButton;
    const $tooltip = $button.nextSibling;

    $tooltip.style.visibility = `${show ? "visible" : "hidden"}`;
    $button.setAttribute("aria-expanded", show.toString());
  };

  const handleTooltipClick = e => {
    displayTooltip(
      e.target,
      e.target.getAttribute("aria-expanded") === "false"
    );
  };

  const handleTooltipEvent = (e, showTooltip) => {
    displayTooltip(e.target, showTooltip);
  };

  const handleTooltipKeyDown = e => {
    if (e.keyCode === 27) {
      displayTooltip(e.target, false);
    } else if (e.keyCode === 32) {
      displayTooltip(e.target, true);
    }
  };

  return (
    <span className="accessible_tooltip">
      <button
        type="button"
        className="accessible_tooltip_button"
        aria-label={ariaLabel}
        aria-expanded="false"
        aria-describedby={tooltipId}
        onClick={e => handleTooltipClick(e)}
        onMouseOver={e => handleTooltipEvent(e, true)}
        onMouseOut={e => handleTooltipEvent(e, false)}
        onFocus={e => handleTooltipEvent(e, true)}
        onBlur={e => handleTooltipEvent(e, false)}
        onKeyDown={e => handleTooltipKeyDown(e)}
      >
        {label}
      </button>
      <div
        id={tooltipId}
        role="tooltip"
        className={classes("accessible_tooltip_content", contentCustomClass)}
        style={{ visibility: "hidden" }}
      >
        <p>{message}</p>
      </div>
    </span>
  );
};

export default AccessibleTooltip;
