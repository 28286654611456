export const COLORS = {
  mint: "#60EDB3",
  graphite: "#2B2823",
  lightPeriwinkle: "#7A97F9",
  lightMint: "#F0F7EE",
  sand: "#E8EAE6",
  ultraLightGray: "#F6F6F6",
  lightGray: "#E9E9E9",
  mediumGray: "#6A7171",
  greenAlert: "#C2F8E1",
  greenSuccess: "#60EDB3",
  greenElement: "#0F8453",
  yellowAlert: "#FFF4B5",
  yellowWarning: "#F4DB51",
  yellowElement: "#A58E0C",
  blueAlert: "#CEDFF8",
  blueInfo: "#8DB5F2",
  blueElement: "#3A5CE4",
  redAlert: "#FFCBCB",
  redError: "#FF6868",
  redElement: "#D62D2D",
};

export const FONTS = {
  regular: "Apercu Regular, sans-serif",
  medium: "Apercu Medium, sans-serif",
  bold: "Apercu Bold, sans-serif",
  display: "Tiempos Headline Bold, serif",
};
