import * as types from "actions/types";

const initialState = {
  suggested_address: {},
  currency: "usd",
  discount_total: null,
  discounts: {},
  fees: { recycling: 0, shipping: 0, total: 0 },
  items: [],
  recycling_fee_rate: null,
  shipping_total: 0,
  subtotal: 0,
  tax: 0,
  total: 0,
  purchase_transaction_key: "",
};

export default (state = initialState, action) => {
  let actions = {
    [types.UPDATE_REGISTER]: () => {
      return {
        ...state,
        ...action.register,
      };
    },
    [types.RESET_REGISTER]: () => {
      return initialState;
    },
    [types.START_OVER]: () => {
      return initialState;
    },
  };

  if (actions[action.type]) {
    return actions[action.type]();
  }
  return state;
};
