import AccessibleTooltip from "AccessibleTooltip";
import { centToDollarNoDecimal } from "helpers/general";
import React from "react";

const WhiteGloveDelivery = ({ priceIncluded, pricePerOrder }) => {
  const deliveryPrice = priceIncluded
    ? "Included"
    : `$${centToDollarNoDecimal(pricePerOrder)}`;
  const message = `After your items are ordered, XPO Logistics will reach out to schedule a delivery date and time window. They will provide full-service mattress setup in the room of your choice, as well as discard all packaging. A single fee of ${deliveryPrice} will be charged for all eligible items in your order.`;

  return (
    <div className="description">
      <div>
        <span>White Glove Delivery - {deliveryPrice}</span>
        <AccessibleTooltip tooltipId="wg_tooltip" message={message} />
      </div>
      <p className="estimate">
        &quot;Fee includes all selected White Glove Delivery items, plus one
        removal per mattress in your White Glove Delivery order.&quot;
      </p>
    </div>
  );
};

export default WhiteGloveDelivery;
