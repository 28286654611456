import React from "react";
import Calendar from "../../svgs/Calendar";
import Truck from "../../svgs/Truck";

const SameDayDeliveryModal = ({ handleCloseModalClick }) => {
  return (
    <div id="delivery_options_modal">
      <div className="icons">
        <Calendar />
        <Truck />
      </div>
      <h4>Same-Day Delivery</h4>
      <p className="sub_header">Get it today</p>
      <p>
        Available until 3:45pm based on your local time zone. Afterwards, orders
        are shipped on the next available day.
      </p>
      <a className="underline_button" onClick={handleCloseModalClick} href="#/">
        Close
      </a>
    </div>
  );
};

export default SameDayDeliveryModal;
