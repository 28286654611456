import { Header, Body } from "components/uikit";
import { COLORS } from "components/uikit/Theme";
import React from "react";
import styled, { css } from "styled-components";
import { humanize, normalizeColorName } from "helpers/general";

const circleSize = 3;

const colorImagePath = (color, family) => {
  const useFamilyInPath = {
    "Quilt": "quilt",
  }
  const colorChipName = useFamilyInPath[family] ? `${useFamilyInPath[family]}-${normalizeColorName(color)}` : normalizeColorName(color);

  return `/images/color_chips/${colorChipName}.jpg`;
}

const selected = props =>
  props.selected &&
  css`
    &:before {
      content: "";
      background-color: ${COLORS.graphite};
      border-radius: 50%;
      height: calc(0.5rem + ${circleSize}rem);
      width: calc(0.5rem + ${circleSize}rem);
      position: absolute;
      top: -0.25rem;
      left: -0.25rem;
      z-index: -2;
    }
    &:after {
      content: "";
      background-color: white;
      border-radius: 50%;
      height: calc(0.25rem + ${circleSize}rem);
      width: calc(0.25rem + ${circleSize}rem);
      position: absolute;
      top: -0.125rem;
      left: -0.125rem;
      z-index: -1;
    }
  `;

const OptionsContainer = styled.div`
  display: flex;
  margin-top: 0.5rem;
`;

const Circle = styled.div`
  position: relative;
  background-color: ${COLORS.ultraLightGray};
  background-image: url(${props => colorImagePath(props.color, props.family)});
  border: 2px solid ${COLORS.lightGray};
  width: ${circleSize}rem;
  height: ${circleSize}rem;
  border-radius: 50%;
  margin-right: 1rem;
  margin-bottom: 1rem;
  ${selected}
`;

const DiagonalLine = styled.div`
  &:before {
    position: absolute;
    display: block;
    content: "";
    width: ${circleSize}rem;
    height: ${circleSize}rem;
  }
  &:after {
    transform: rotate(-45deg)
      translate(-${circleSize / 3}rem, ${circleSize / 3}rem);
    top: 0;
    width: ${circleSize}rem;
    height: 0.0625rem;
    background-color: ${COLORS.mediumGray};
    border-top: solid 0.0625rem white;
    border-bottom: solid 0.0625rem white;
    position: absolute;
    display: block;
    content: "";
  }
`;

const HeaderRow = styled.div`
  display: flex;
`;
const HeaderLabel = styled(Header.H5)`
  margin-right: 0.5rem;
`;

const ColorSelector = ({
  colors,
  productFamily,
  selectedColor,
  setSelectedColor,
  selectableColors,
}) => {
  const colorOptions = colors.map(color => ({
    value: color,
    disabled: !selectableColors.includes(color),
  }));

  if (colors.length <= 1) return null;

  return (
    <div data-testid="color-selector">
      <HeaderRow>
        <HeaderLabel>Color</HeaderLabel>
        <Body.P>{humanize(selectedColor)}</Body.P>
      </HeaderRow>
      <OptionsContainer>
        {colorOptions.map(option => (
          <Circle
            key={option.value}
            onClick={() => setSelectedColor(option.value)}
            selected={option.value === selectedColor}
            color={option.value}
            family={productFamily}
            data-testid="color-selector-option"
          >
            {option.disabled && <DiagonalLine />}
          </Circle>
        ))}
      </OptionsContainer>
    </div>
  );
};

export default ColorSelector;
