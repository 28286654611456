import { formattedSize, formattedWeight } from "data/products";
import { humanize } from "helpers/general";

export const productImage = ({ sku }) => {
  return sku.includes("TS-TN") ? `/images/skus/shirts/${sku}` : `/images/skus/products/${scrubSku(sku)}.jpg`;
};

export const scrubSku = (productSku) => {
  const regex = /[A-Z]/;
  // handles mattress protector edgecase
  if (productSku.includes("PR")) {
    return productSku
  } else {
    const scrubbedSku = productSku.replace("/", "-").split("-").filter((sku) => {
      if ((sku.length > 1) && (regex.test(sku))) {
        return sku
      } else {
        return false
      }
    }).join("-");
    return scrubbedSku ? scrubbedSku : productSku;
  };
};

export const sizeLabel = size => formattedSize[size] || humanize(size);

export const weightLabel = weight =>
  formattedWeight[weight] || humanize(weight);

const sortString = (str1, str2) => {
  if (str1 > str2) {
    return 1;
  }
  if (str1 < str2) {
    return -1;
  }
  return 0;
};

const byCategory = (p1, p2) => {
  const category1 = p1.productFamily.category.name;
  const category2 = p2.productFamily.category.name;
  return sortString(category2, category1);
};

const byFullName = (p1, p2) => {
  const family1 = p1.productFamily.fullName;
  const family2 = p2.productFamily.fullName;
  return sortString(family2, family1);
};

const bySize = (p1, p2) => {
  const sizeKeys = Object.keys(formattedSize);
  return sizeKeys.indexOf(p1.size) - sizeKeys.indexOf(p2.size);
};

const byWeight = (p1, p2) => {
  const weightKeys = Object.keys(formattedWeight);
  return weightKeys.indexOf(p1.weight) - weightKeys.indexOf(p2.weight);
};

const byColor = (p1, p2) => {
  return sortString(p1.color, p2.color);
};

export const sortProducts = products => {
  return [byCategory, byFullName, byColor, bySize, byWeight]
    .reverse()
    .reduce((acc, sorter) => acc.sort(sorter), products);
};

const formatWeight = weight => (weight > 0 ? `${weight} Lbs` : "");

const formatDimension = ({ length: l, width: w, height: h }) =>
  [l > 0 && `${l}"L`, w > 0 && `${w}"W`, h > 0 && `${h}"H`]
    .filter(x => x)
    .join(" x ");

const formatPhysicalDimension = product =>
  formatDimension({
    length: product.physicalLengthInches,
    width: product.physicalWidthInches,
    height: product.physicalHeightInches,
  });

const formatShippingDimension = product =>
  formatDimension({
    length: product.shippingLengthInches,
    width: product.shippingWidthInches,
    height: product.shippingHeightInches,
  });

export const buildDimensionsMap = ({ products }) => {
  return products.reduce(
    (acc, product) => ({
      ...acc,
      [product.size]: {
        physical: formatPhysicalDimension(product),
        shipping: formatShippingDimension(product),
        weight: formatWeight(product.shippingWeightPounds),
      },
    }),
    {}
  );
};
