import CartItems from "components/cart/CartItems";
import { Button, Link } from "components/uikit";
import AccessibleTooltip from "AccessibleTooltip";
import { loadRegister } from "actions/register";
import { buildCart, cartSubtotal } from "helpers/cart";
import { centToDollar } from "helpers/general";
import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & * {
    margin-bottom: 1rem;
  }
`;

const estimatedTaxMessage = "Sales tax is estimated based on current location and is subject to change for shipped items.";

const CartContainer = ({ history }) => {
  const dispatch = useDispatch();
  const cart = useSelector(state => buildCart(state));
  const register = useSelector(state => state.register);
  const shippingTotal = useSelector(state =>
    buildShippingTotal(state.lineItems)
  );
  const appliedHurdle = useSelector(state => state.spendHurdle.appliedHurdle);
  const loadRegisterWithStoreInfo = true;

  useEffect(() => {
    dispatch(loadRegister(loadRegisterWithStoreInfo));
  }, [dispatch, loadRegisterWithStoreInfo]);

  const total = useCallback(() => {
    const subtotal = cartSubtotal(cart) * 100;
    const hurdleDiscount = appliedHurdle ? appliedHurdle.options.discount : 0;
    return (
      (subtotal - hurdleDiscount + shippingTotal) /
      100
    ).toFixed(2);
  }, [cart, appliedHurdle, shippingTotal]);

  const estimatedTotal = useMemo(() => {
    const totalPlusTax = Number(total()) + Number(centToDollar(register.tax));
    return totalPlusTax.toFixed(2);
  }, [total, register]);

  const handleBackClick = e => {
    e.preventDefault();
    history.goBack();
  };

  const handleCheckoutClick = e => {
    e.preventDefault();
    history.push("/checkout/customer_info");
  };

  const discount = appliedHurdle
    ? centToDollar(appliedHurdle.options.discount)
    : 0;

  return (
    <div id="cart">
      <a className="close_icon" onClick={handleBackClick} href="#/">
        Close
      </a>
      <h2>Review Order</h2>
      {cart.length > 0 ? (
        <div>
          <CartItems cart={cart} />
          <div className="price_summary">
            <dl className="subtotals">
              <dt>Item Subtotal</dt>
              <dd>${cartSubtotal(cart)}</dd>
              {appliedHurdle ? <dt>Discounts</dt> : null}
              {appliedHurdle ? <dd>-${discount}</dd> : null}
              <dt>Shipping Subtotal</dt>
              <dd>{centToDollar(shippingTotal)}</dd>
              <dt>Recycling Fee</dt>
              <dd>-</dd>
              <dt>Estimated Tax<AccessibleTooltip tooltipId="estimated_tax_tooltip" message={estimatedTaxMessage} contentCustomClass="estimated_tax_message" /></dt>
              <dd>${centToDollar(register.tax)}</dd>
            </dl>
            <dl className="total">
              <dt>Total</dt>
              <dd>${estimatedTotal}</dd>
            </dl>
          </div>
          <div className="actions">
            <ActionContainer>
              <Button primary onClick={handleCheckoutClick}>
                Checkout
              </Button>
              <Link secondary onClick={handleBackClick}>
                Continue Shopping
              </Link>
            </ActionContainer>
          </div>
        </div>
      ) : (
        <div id="empty_cart">
          <p>Nothing to review. Your cart is empty!</p>
          <ActionContainer>
            <Button primary onClick={() => history.push("/")}>
              Get Shopping
            </Button>
          </ActionContainer>
        </div>
      )}
    </div>
  );
};

const sum = array => array.reduce((acc, value) => acc + value, 0);

const buildShippingTotal = lineItems => {
  const selectedDeliveryOptions = lineItems.map(lineItem =>
    lineItem.delivery_options.find(d => d.name === lineItem.delivery_method)
  );

  const totalPrice = sum(selectedDeliveryOptions.map(({ price }) => price));

  const pricePerOrderMap = selectedDeliveryOptions.reduce(
    (acc, deliveryOption) => ({
      ...acc,
      [deliveryOption.name]: deliveryOption.price_per_order || 0,
    }),
    {}
  );
  const totalPricePerOrder = sum(Object.values(pricePerOrderMap));

  return totalPrice + totalPricePerOrder;
};

export default CartContainer;
