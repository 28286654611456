import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Control, Errors, actions } from "react-redux-form";
import { passwordReset } from "../actions/auth";
import { notEmpty } from "../helpers/forms";

class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.handleResetClick = this.handleResetClick.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(
      actions.change(
        "login.resetToken",
        this.props.location.search.replace("?id=", "")
      )
    );
  }

  addInvalidClass(fieldName) {
    return this.props.loginForm[fieldName].valid ? "" : "invalid";
  }

  handleResetClick() {
    this.props
      .dispatch(passwordReset(this.props.resetToken))
      .then(() => {
        this.props.history.push("/login/password_reset_successful");
      })
      .catch(errorMessage => {
        this.props.dispatch(
          actions.setErrors("login.newPassword", errorMessage)
        );
      });
  }

  render() {
    return (
      <div id="password_reset">
        <h2>Reset Password</h2>
        <Form
          model="login"
          onSubmit={this.handleResetClick}
          validateOn="submit"
          validators={{
            newPassword: { required: notEmpty },
            newPasswordConfirm: {
              required: notEmpty,
            },
          }}
        >
          <dl className={this.addInvalidClass("newPassword")}>
            <dt>
              <label htmlFor="newPassword">New Password</label>
            </dt>
            <dd>
              <Control.text
                model=".newPassword"
                dynamic={false}
                id="newPassword"
                name="newPassword"
                type="password"
              />
              <Errors
                className="error"
                model=".newPassword"
                messages={{ required: "Enter your new password" }}
                wrapper="p"
              />
            </dd>
          </dl>
          <dl className={this.addInvalidClass("newPasswordConfirm")}>
            <dt>
              <label htmlFor="newPasswordConfirm">Retype Password</label>
            </dt>
            <dd>
              <Control.text
                model=".newPasswordConfirm"
                dynamic={false}
                id="newPasswordConfirm"
                name="newPasswordConfirm"
                type="password"
              />
              <Errors
                className="error"
                model=".newPasswordConfirm"
                messages={{ required: "Enter your new password again" }}
                wrapper="p"
              />
            </dd>
          </dl>
          <input
            className="primary_button"
            name="resetPassword"
            type="submit"
            value="Reset Password"
          />
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loginForm: state.loginForm,
    resetToken: state.login.resetToken,
  };
}

export default connect(mapStateToProps)(ResetPasswordForm);
