import * as API from "../api";
import * as types from "./types";

export const loadSourceAttributions = () => {
  return (dispatch, getState) => {
    return new Promise(resolve => {
      let addressState = getState().order.receipt.address_state;
      let params = addressState ? { state: addressState } : {};

      API.getSourceAttributions(params).then(resp => {
        dispatch({
          type: types.SET_SOURCE_ATTRIBUTIONS,
          payload: resp.data,
        });
        resolve();
      });
    });
  };
};

export const setSourceCategoryId = categoryId => {
  return {
    type: types.SET_SOURCE_CATEGORY_ID,
    categoryId,
  };
};

export const setSourceSubcategory = categoryName => {
  return {
    type: types.SET_SOURCE_SUBCATEGORY,
    categoryName,
  };
};

export const setSavedSourceAttribution = (categoryId, categoryName) => {
  return {
    type: types.SET_SAVED_SOURCE_ATTRIBUTION,
    categoryId,
    categoryName,
  };
};

export const submitSourceAttribution = () => {
  return (dispatch, getState) => {
    return new Promise(resolve => {
      let sa = getState().sourceAttributions;
      let data = {
        slug: getState().order.receipt.slug,
        email: getState().order.receipt.email,
        source_attribution_category_id: sa.selectedCategoryId,
      };

      if (sa.selectedSubcategoryName) {
        data.source_attribution_name = sa.selectedSubcategoryName;
      }

      API.submitSourceAttribution(data).then(() => {
        resolve();
      });
    });
  };
};

export const resetSourceAttributionUI = () => {
  return dispatch => {
    dispatch(setSourceCategoryId(null));
    dispatch(setSourceSubcategory(null));
  };
};
