import * as types from "../actions/types";

const initialState = {
  sources: [],

  // These are for UI state, not yet submitted
  selectedCategoryId: null,
  selectedSubcategoryName: null,

  // These have been submitted to API, or set from API
  savedCategoryId: null,
  savedSubcategoryName: null,
};

export default (state = initialState, action) => {
  let actions = {
    [types.SET_SOURCE_ATTRIBUTIONS]: () => {
      return {
        ...state,
        sources: action.payload,
      };
    },
    [types.SET_SOURCE_CATEGORY_ID]: () => {
      return {
        ...state,
        selectedCategoryId: action.categoryId,
        selectedSubcategoryName: null,
      };
    },
    [types.SET_SOURCE_SUBCATEGORY]: () => {
      return {
        ...state,
        selectedSubcategoryName: action.categoryName,
      };
    },
    [types.SET_SAVED_SOURCE_ATTRIBUTION]: () => {
      return {
        ...state,
        savedCategoryId: action.categoryId,
        savedSubcategoryName: action.categoryName,
      };
    },
  };

  if (actions[action.type]) {
    return actions[action.type]();
  }
  return state;
};
