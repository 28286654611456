import * as types from "../actions/types";

const initialState = {
  contact: { completed: false },
  shipping: { completed: false },
  review: { completed: false },
  payment: { completed: false },
};

export default (state = initialState, action) => {
  let actions = {
    [types.SET_STEP_COMPLETED]: () => {
      return {
        ...state,
        [action.step]: { completed: true },
      };
    },
    [types.SET_STEP_NOT_COMPLETED]: () => {
      return {
        ...state,
        [action.step]: { completed: false },
      };
    },
    [types.RESET_BREADCRUMB]: () => {
      return initialState;
    },
  };

  if (actions[action.type]) {
    return actions[action.type]();
  }
  return state;
};
