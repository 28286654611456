import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { Body, Header, Theme } from "components/uikit";
import { DELIVERY_METHODS, SAME_DAY } from "data/deliveryMethods";
import { formattedPrice } from "helpers/delivery";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import ZipInputContainer from "./ZipInputContainer";

export const GET_DELIVERY_OPTIONS = gql`
  query GetDeliveryOptions($skus: [String!], $zip: String) {
    products: getRetailProducts(skus: $skus) {
      deliveryOptions(zip: $zip, channel: "retail") {
        name
        inventoryCount
        available
        price
        pricePerOrder
      }
    }
  }
`;

const DeliveryOptionContainer = styled.div`
  margin: 1.5rem 0;
`;
const DeliveryOptionTitle = styled(Header.H5)``;
const DeliveryOptionRow = styled.div`
  margin-top: 0.5rem;
`;
const DeliveryOptionName = styled(Body.P)`
  display: inline;
  margin-left: 0.5rem;
  ${props => props.disabled && { color: Theme.COLORS.mediumGray }};
`;
const DeliveryOptionPrice = styled(Body.P)`
  display: inline;
  float: right;
  ${props => props.disabled && { color: Theme.COLORS.mediumGray }};
`;
const Loading = styled(Body.P)`
  margin-left: 1.2rem;
`;
const DeliveryOptionStock = styled(Body.P)`
  margin-left: 1.2rem;
  color: ${props =>
    props.disabled ? Theme.COLORS.mediumGray : Theme.COLORS.blueElement};
`;

const displayPrice = (deliveryOption, lineItemDeliveryMethods) => {
  if (deliveryOption.pricePerOrder) {
    if (lineItemDeliveryMethods.includes(deliveryOption.name)) {
      return "Included";
    } else {
      return formattedPrice(deliveryOption.pricePerOrder);
    }
  } else {
    return formattedPrice(deliveryOption.price);
  }
};

const DeliveryOptionSelector = ({
  product,
  selectedDeliveryOption,
  setSelectedDeliveryOption,
}) => {
  const { sku } = product;
  const zip = useSelector(state => state.zip.zip);
  const { data, loading } = useQuery(GET_DELIVERY_OPTIONS, {
    variables: { skus: [sku], zip },
    skip: !sku,
  });
  const productData = data && data.products && data.products[0];
  const deliveryOptions = (productData && productData.deliveryOptions) || [];
  const firstOption = deliveryOptions.find(({ available }) => available);
  const lineItemDeliveryMethods = useSelector(state =>
    state.lineItems.map(li => li.delivery_method)
  );

  useEffect(() => {
    firstOption && setSelectedDeliveryOption(firstOption);
  }, [firstOption, sku, setSelectedDeliveryOption]);

  if (!deliveryOptions.length) return null;

  return (
    <DeliveryOptionContainer>
      <DeliveryOptionTitle>Delivery Options</DeliveryOptionTitle>
      {Object.keys(DELIVERY_METHODS).map(method => {
        const deliveryOption = deliveryOptions.find(d => d.name === method);
        if (!deliveryOption) {
          return null;
        }
        const { inventoryCount, name, available } = deliveryOption;
        const hasInventory = inventoryCount !== null;
        const isSameDay = name === SAME_DAY;
        const showLoading = loading && hasInventory;
        const showInStock = !loading && (isSameDay ? zip : hasInventory);
        const showZipLabel = !loading && !zip;
        const enabledOption = available || isSameDay;

        return (
          <React.Fragment key={name}>
            <DeliveryOptionRow>
              <label>
                <input
                  type="radio"
                  name={`[${sku}].delivery_method`}
                  checked={
                    !!selectedDeliveryOption &&
                    selectedDeliveryOption.name === name
                  }
                  value={name}
                  onChange={() => setSelectedDeliveryOption(deliveryOption)}
                  data-testid="delivery-options-option"
                />
                <DeliveryOptionName disabled={!enabledOption}>
                  {DELIVERY_METHODS[name].name}
                </DeliveryOptionName>
              </label>
              <DeliveryOptionPrice disabled={!enabledOption}>
                {displayPrice(deliveryOption, lineItemDeliveryMethods)}
              </DeliveryOptionPrice>
              {showLoading && <Loading>Loading...</Loading>}
              {showInStock && (
                <DeliveryOptionStock disabled={!available}>
                  {inventoryCount} in stock{available && "!"}
                </DeliveryOptionStock>
              )}
            </DeliveryOptionRow>
            {isSameDay && (
              <ZipInputContainer
                available={available}
                loading={loading}
                showZipLabel={showZipLabel}
              />
            )}
          </React.Fragment>
        );
      })}
    </DeliveryOptionContainer>
  );
};

export default DeliveryOptionSelector;
