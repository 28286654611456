import { css } from "styled-components";
import { COLORS, FONTS } from "./Theme";

export const classNames = (originalClassName, options) =>
  [
    originalClassName,
    ...Object.keys(options).filter(option => options[option]),
  ].join(" ");

export const center = props =>
  props.center &&
  css`
    text-align: center;
  `;

export const bold = props =>
  props.bold &&
  css`
    font-family: ${FONTS.bold};
  `;

export const light = props =>
  props.light &&
  css`
    color: ${COLORS.mediumGray};
  `;

export const inline = props =>
  props.inline &&
  css`
    display: inline;
  `;

export const hideOnPrint = props =>
  props.hideOnPrint &&
  css`
    @media print {
      display: none !important;
    }
  `;
