import { setZipAction } from "actions/zip";
import { Form } from "components/uikit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const ZipContainer = styled.div`
  margin-left: 1.2rem;
`;

const messages = {
  [Form.STATUS.SUCCESS]: "Same day available for this zip!",
  [Form.STATUS.WARNING]: "Same day currently unavailable for this zip.",
};

const getZipStatus = ({ zip, available, loading }) => {
  if (!zip || loading) return;

  return available ? Form.STATUS.SUCCESS : Form.STATUS.WARNING;
};

const useZip = () => {
  const zip = useSelector(state => state.zip.zip);
  const [unbouncedZip, setUnbouncedZip] = useState(zip);
  const dispatch = useDispatch();

  useEffect(() => {
    setUnbouncedZip(zip);
  }, [zip]);

  useEffect(() => {
    const handler = setTimeout(() => {
      dispatch(setZipAction(unbouncedZip));
    }, 500);
    return () => clearTimeout(handler);
  }, [unbouncedZip, dispatch]);

  return { zip, unbouncedZip, setUnbouncedZip };
};

const ZipInputContainer = ({ available, loading, showZipLabel }) => {
  const { zip, unbouncedZip, setUnbouncedZip } = useZip();
  const zipStatus = getZipStatus({ zip, available, loading });

  return (
    <ZipContainer>
      <Form.InputContainer
        label={showZipLabel && "Enter zip to check availability:"}
        message={zipStatus && messages[zipStatus]}
        status={zipStatus}
        onChange={e => setUnbouncedZip(e.target.value)}
        type="zip"
        value={unbouncedZip}
      />
    </ZipContainer>
  );
};

export default ZipInputContainer;
