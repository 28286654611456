import { closeModal } from "actions/modals";
import CancelOrderModal from "components/modals/CancelOrderModal";
import ExitCheckoutModal from "components/modals/ExitCheckoutModal";
import InvalidAddressModal from "components/modals/InvalidAddressModal";
import ProcessingModal from "components/modals/ProcessingModal";
import ResetLinkSentModal from "components/modals/ResetLinkSentModal";
import SameDayDeliveryModal from "components/modals/SameDayDeliveryModal";
import SizeChartModal from "components/modals/SizeChartModal";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "underscore";

export const MODAL_NAMES = {
  RESET_LINK_SENT: "resetLinkSent",
  SIZE_CHART: "sizeChart",
  PROCESSING: "processing",
  EXIT_CHECKOUT: "exitCheckout",
  SAME_DAY_DELIVERY: "sameDayDelivery",
  CANCEL_ORDER: "cancelOrder",
  INVALID_ADDRESS: "invalidAddress",
};

const ModalContainer = ({ history }) => {
  const dispatch = useDispatch();
  const modals = useSelector(state => state.modals);

  const renderModalContent = openedModal => {
    const modals = {
      [MODAL_NAMES.RESET_LINK_SENT]: <ResetLinkSentModal />,
      [MODAL_NAMES.SIZE_CHART]: <SizeChartModal />,
      [MODAL_NAMES.PROCESSING]: <ProcessingModal />,
      [MODAL_NAMES.EXIT_CHECKOUT]: (
        <ExitCheckoutModal
          handleCloseModalClick={handleCloseModalClick}
          redirect={history.push}
        />
      ),
      [MODAL_NAMES.SAME_DAY_DELIVERY]: (
        <SameDayDeliveryModal handleCloseModalClick={handleCloseModalClick} />
      ),
      [MODAL_NAMES.CANCEL_ORDER]: (
        <CancelOrderModal handleCloseModalClick={handleCloseModalClick} />
      ),
      [MODAL_NAMES.INVALID_ADDRESS]: (
        <InvalidAddressModal redirect={history.push} />
      ),
    };

    return modals[openedModal];
  };

  const handleModalLayerClick = modalName =>
    modalName !== MODAL_NAMES.PROCESSING && dispatch(closeModal());

  const handleCloseModalClick = e => {
    e.preventDefault();
    dispatch(closeModal());
  };

  const openedModal = _.findKey(modals, value => {
    return value.open;
  });

  return openedModal ? (
    <div className="modal_container">
      <div
        className="modal_layer"
        onClick={() => handleModalLayerClick(openedModal)}
      ></div>
      <div className="modal">
        {openedModal === "processing" ||
        openedModal === "resetLinkSent" ? null : (
          <a className="close_icon" onClick={handleCloseModalClick} href="#/">
            Close
          </a>
        )}
        {renderModalContent(openedModal)}
      </div>
    </div>
  ) : null;
};

export default ModalContainer;
