import axios from "axios";
import { apiBaseUrl } from "config";

const attr = sel => {
  var el = document.querySelector(sel);
  return el ? el.getAttribute("content") : null;
};
const version = attr("[name=api_version]");

const route = {
  authenticate: "retail_sessions",
  resetPassword: "retail/password_resets",
  promotions: "promotions",
  register: "retail_checkouts/register",
  purchase: "retail_checkouts/purchase",
  cancel: "public_orders/cancel",
  sourceAttributions: "source_attributions",
  people: "people",
  stripeConnectionToken: `${version}/retail/stripe_terminal/connection_tokens`,
  stripePaymentIntent: `${version}/retail/stripe_terminal/payment_intents`,
};

export const client = axios.create({
  baseURL: apiBaseUrl,
  withCredentials: true,
});

export function auth(loginParams) {
  return client.post(route.authenticate, loginParams);
}

export function sendResetLink(email) {
  return client.post(`${version}/${route.resetPassword}`, { email });
}

export function resetPassword(token, password, password_confirmation) {
  return client.put(`${version}/${route.resetPassword}`, {
    id: token,
    user: { password, password_confirmation },
  });
}

export function getSpendHurdle() {
  return client.get(`${version}/${route.promotions}`);
}

export function loadRegister(cart, storeKey) {
  return client.post(route.register, {
    cart,
    store_key: storeKey,
  });
}

export function placeOrder(order, storeKey) {
  return client.post(route.purchase, {
    ...order,
    store_key: storeKey,
  });
}

export function cancelOrder(data) {
  return client.post(route.cancel, data);
}

export function getSourceAttributions(params) {
  return client.get(`${version}/${route.sourceAttributions}`, params);
}

export function submitSourceAttribution(data) {
  return client.put(`${version}/${route.people}`, data);
}

export const createStripeConnectionToken = ({ storeKey: store_key }) => {
  return client
    .post(route.stripeConnectionToken, { store_key })
    .then(response => response.data.secret);
};

export const createStripePaymentIntent = ({
  storeKey,
  purchaseTransactionKey,
}) => {
  return client
    .post(route.stripePaymentIntent, {
      store_key: storeKey,
      purchase_transaction_key: purchaseTransactionKey,
    })
    .then(response => response.data.client_secret);
};
