import { setStepCompleted, setStepsNotCompleted } from "actions/breadcrumb";
import { closeModal, openModal } from "actions/modals";
import { loadRegister } from "actions/register";
import { SET_PROMOTION_KEYS } from "actions/types";
import CancelCheckoutLink from "components/checkout/CancelCheckoutLink";
import ReviewLineItems from "components/checkout/ReviewLineItems";
import { Body } from "components/uikit";
import { formatAddress } from "helpers/address";
import { centToDollar } from "helpers/general";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Promotions from "./Promotions";

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AddressValue = styled(Body.P)`
  white-space: pre-line;
`;

const ReviewContainer = ({ history }) => {
  const dispatch = useDispatch();
  const register = useSelector(state => state.register);
  const customerInfo = useSelector(state => state.customerInfo);

  useEffect(() => {
    dispatch(setStepsNotCompleted(["review"]));
  }, [dispatch]);

  const handleNextClick = () => {
    dispatch(setStepCompleted("review"));
    history.push("/checkout/payment");
  };

  const handleSelectPromoChange = promotionKeys => {
    dispatch({ type: SET_PROMOTION_KEYS, promotionKeys });
    dispatch(openModal("processing"));
    dispatch(loadRegister()).then(() => {
      dispatch(closeModal());
    });
  };

  const formattedAddress = formatAddress(customerInfo);

  return (
    <div id="review">
      <h2>Review Order</h2>
      <div className="price_summary">
        <h4>Price Summary</h4>
        <ReviewLineItems />
        <RowContainer>
          <Promotions handleSelectPromoChange={handleSelectPromoChange} />
          <div className="price_detail">
            <dl className="subtotals">
              <dt>Subtotal</dt>
              <dd>${centToDollar(register.subtotal)}</dd>
              <dt>Discounts</dt>
              <dd>-${centToDollar(register.discount_total)}</dd>
              <dt>Shipping Subtotal</dt>
              <dd>${centToDollar(register.shipping_total)}</dd>
              <dt>Recycling Fee</dt>
              <dd>${centToDollar(register.recycling_fee_rate)}</dd>
              <dt>Tax</dt>
              <dd>${centToDollar(register.tax)}</dd>
            </dl>
            <dl className="total">
              <dt>Total</dt>
              <dd>${centToDollar(register.total)}</dd>
            </dl>
          </div>
        </RowContainer>
      </div>
      <div className="shipping_info">
        <h4>Delivering To</h4>
        <div className="flex_container">
          <div>
            <p>{`${customerInfo.firstname} ${customerInfo.lastname}`}</p>
            {formattedAddress && (
              <AddressValue>{formattedAddress}</AddressValue>
            )}
          </div>
          <div>
            <p>{customerInfo.email}</p>
            <p>{customerInfo.phone}</p>
          </div>
        </div>
      </div>
      <input
        className="primary_button"
        name="Next: Payment"
        type="submit"
        value="Next: Payment"
        onClick={handleNextClick}
      />
      <CancelCheckoutLink />
    </div>
  );
};

export default ReviewContainer;
