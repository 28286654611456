import { setStepsNotCompleted } from "actions/breadcrumb";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import DeliveryForm from "./DeliveryForm";
import RequestDeliveryDatesForm from "./RequestDeliveryDatesForm";

const DeliveryContainer = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setStepsNotCompleted(["shipping", "review"]));
  }, [dispatch]);

  return (
    <div id="deliveries">
      <Switch>
        <Route exact path="/checkout/delivery" component={DeliveryForm} />
        <Route
          path="/checkout/delivery/delivery-dates"
          component={RequestDeliveryDatesForm}
        />
      </Switch>
    </div>
  );
};

export default DeliveryContainer;
