import * as types from "./types";

export const openModal = (modal, payload) => {
  return {
    type: types.OPEN_MODAL,
    modal,
    payload,
  };
};

export const closeModal = modal => {
  return {
    type: types.CLOSE_MODAL,
    modal,
  };
};
