import { setStepCompleted } from "actions/breadcrumb";
import {
  addFlashMessageAction,
  clearAllFlashMessagesAction,
  TYPES,
} from "actions/flashMessages";
import { updateDeliveryMethods } from "actions/lineItems";
import {
  closeModal as closeModalAction,
  openModal as openModalAction,
} from "actions/modals";
import { loadRegister as loadRegisterAction } from "actions/register";
import CancelCheckoutLink from "components/checkout/CancelCheckoutLink";
import { Button } from "components/uikit";
import { WHITE_GLOVE } from "data/deliveryMethods";
import { isAddressRequired } from "helpers/cart";
import useSelectedProducts from "hooks/useSelectedProducts";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-redux-form";
import styled from "styled-components";
import Delivery from "./Delivery";

const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
`;
const ActionCell = styled.span`
  display: flex;
  flex: 1;
  justify-content: ${props => (props.right ? "flex-end" : "flex-start")};
  margin: 0 0.5rem;
`;

const useWhiteGloveSelection = ({ selectedDeliveryMethods }) => {
  const dispatch = useDispatch();
  const items = useSelector(state => state.lineItems);

  const [whiteGloveSelected, setWhiteGloveSelected] = useState(
    selectedDeliveryMethods.includes(WHITE_GLOVE)
  );

  useEffect(() => {
    const autoSelectWhiteGloveEligibleOptions = () => {
      const newDeliveryMethods = items.map((item, index) => {
        const isWhiteGloveEligible = item.delivery_options.find(
          option => option.name === WHITE_GLOVE
        );
        return isWhiteGloveEligible
          ? WHITE_GLOVE
          : selectedDeliveryMethods[index];
      });
      dispatch(updateDeliveryMethods(newDeliveryMethods));
    };

    const newSelectionContainsWhiteGlove = selectedDeliveryMethods.includes(
      WHITE_GLOVE
    );
    setWhiteGloveSelected(newSelectionContainsWhiteGlove);

    if (!whiteGloveSelected && newSelectionContainsWhiteGlove) {
      autoSelectWhiteGloveEligibleOptions();
    }
  }, [dispatch, items, selectedDeliveryMethods, whiteGloveSelected]);
};

const DeliveryForm = ({ history }) => {
  const dispatch = useDispatch();
  const products = useSelectedProducts();
  const lineItems = useSelector(state => state.lineItems);
  const validAddress = useSelector(state => state.register.valid_address);
  const addressRequired = isAddressRequired(lineItems);
  const addressVerified = useSelector(
    state => state.customerInfo.addressVerified
  );
  const selectedDeliveryMethods = useSelector(state =>
    state.lineItems.map(i => i.delivery_method)
  );
  const requestedDeliveryDates = useSelector(state =>
    state.lineItems.map(i => i.requested_delivery_date)
  );

  const openModal = modal => dispatch(openModalAction(modal));
  const closeModal = () => dispatch(closeModalAction());
  const loadRegister = () => dispatch(loadRegisterAction());
  const completeStep = step => dispatch(setStepCompleted(step));

  useWhiteGloveSelection({ selectedDeliveryMethods });

  const handleSameDayModalClick = e => {
    e.preventDefault();
    openModal("sameDayDelivery");
  };

  const handleFormSubmit = () => {
    if (addressRequired && !validAddress && !addressVerified) {
      const message = {
        type: TYPES.error,
        text: "Please enter a shipping address.",
      };
      dispatch(addFlashMessageAction(message));
      history.push("/checkout/customer_info");
    } else {
      dispatch(clearAllFlashMessagesAction());
      openModal("processing");
      loadRegister().then(() => {
        closeModal();
        history.push("/checkout/review");
        completeStep("shipping");
      });
    }
  };

  const showRequestDeliveryDatesForm = () => {
    history.push("/checkout/delivery/delivery-dates");
  };

  const allowBulkRequestDeliveryDate = selectedDeliveryMethods.some(
    (deliveryMethod, i) => {
      const deliveryOption = lineItems[i].delivery_options.find(
        ({ name }) => name === deliveryMethod
      );

      return (
        deliveryOption && deliveryOption.requestable_delivery_dates.length > 0
      );
    }
  );

  return (
    <Form
      model="lineItems"
      onSubmit={handleFormSubmit}
      data-testid="deliveries-form"
    >
      <fieldset>
        {lineItems.map((registerItem, index) => (
          <Delivery
            key={index}
            deliveriesQuantity={lineItems.length}
            registerItem={registerItem}
            deliveryNumber={index + 1}
            handleSameDayModalClick={handleSameDayModalClick}
            closeModal={closeModal}
            product={products[registerItem.sku]}
            currentDeliveryMethod={selectedDeliveryMethods[index]}
            selectedDeliveryMethods={selectedDeliveryMethods}
            requestedDeliveryDates={requestedDeliveryDates}
          />
        ))}
        <ActionContainer>
          <ActionCell right>
            <Button
              secondary
              disabled={!allowBulkRequestDeliveryDate}
              onClick={showRequestDeliveryDatesForm}
            >
              Request new delivery dates
            </Button>
          </ActionCell>
          <ActionCell>
            <Button type="submit">Next: Review Order</Button>
          </ActionCell>
        </ActionContainer>
        <CancelCheckoutLink />
      </fieldset>
    </Form>
  );
};

export default DeliveryForm;
