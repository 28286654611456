import { clearAllFlashMessagesAction } from "actions/flashMessages";
import CancelCheckoutLink from "components/checkout/CancelCheckoutLink";
import Divider from "components/Divider";
import { Body } from "components/uikit";
import { stripeCardReaderSimulated } from "config";
import { centToDollar } from "helpers/general";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import PaymentForm from "./PaymentForm";
import StripeCardReader from "./StripeCardReader";

const PaymentMethodContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
}`;

const DividerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
`;

const DividerText = styled(Body.P)`
  margin: 0 1rem;
`;

const Total = styled(Body.PLarge)`
  margin-bottom: 2rem;
`;

const PaymentContainer = ({ history }) => {
  const dispatch = useDispatch();
  const {
    total,
    purchase_transaction_key: purchaseTransactionKey,
  } = useSelector(state => state.register);
  const storeInfo = useSelector(state => state.storeInfo);
  const stripeCardReaderEnabled =
    stripeCardReaderSimulated || storeInfo.stripeLocationId;

  const nextStep = () => {
    history.push("/receipt");
  };

  useEffect(() => {
    return () => dispatch(clearAllFlashMessagesAction());
  }, [dispatch]);

  return (
    <div id="payment">
      <h2>Payment</h2>
      <Total data-testid="total">Order Total: ${centToDollar(total)}</Total>

      <PaymentMethodContainer>
        <img
          alt="Visa, Mastercard, American Express, and Discover accepted"
          src="/images/payment_types.png"
        />
      </PaymentMethodContainer>
      {stripeCardReaderEnabled && (
        <>
          <StripeCardReader
            onSuccess={nextStep}
            storeInfo={storeInfo}
            purchaseTransactionKey={purchaseTransactionKey}
          />
          <DividerContainer>
            <Divider />
            <DividerText>OR</DividerText>
            <Divider />
          </DividerContainer>
        </>
      )}
      <PaymentForm nextStep={nextStep} />
      <CancelCheckoutLink />
    </div>
  );
};

export default PaymentContainer;
