import { Header } from "components/uikit";
import { ButtonSelect } from "components/uikit/Form";
import { weightLabel } from "helpers/products";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin: 1.5rem 0;
`;
const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const WeightSelector = ({
  weights,
  selectedWeight,
  setSelectedWeight,
  selectableWeights,
}) => {
  const weightOptions = weights.map(weight => ({
    value: weight,
    label: weightLabel(weight),
    disabled: !selectableWeights.includes(weight),
  }));

  if (weightOptions.length <= 1) return null;

  return (
    <Container data-testid="weight-selector">
      <LabelContainer>
        <Header.H5>Weight</Header.H5>
      </LabelContainer>
      <ButtonSelect
        options={weightOptions}
        selected={selectedWeight}
        onChange={setSelectedWeight}
      />
    </Container>
  );
};

export default WeightSelector;
