import * as API from "../api";
import { closeModal, openModal } from "./modals";
import * as types from "./types";
import { setLoginData } from "helpers/login";

export const login = loginParams => {
  return dispatch => {
    dispatch(openModal("processing"));
    return new Promise((resolve, reject) => {
      API.auth(loginParams)
        .then(({ data }) => {
          if (data.store_key) {
            setLoginData(data);
            dispatch(setStoreInfo(data));
            dispatch(closeModal());
            resolve();
          } else {
            reject();
          }
        })
        .catch(({response}) => {
          reject((response && response.data && response.data.error) || "Unexpected error, please try again.");
        });
    });
  };
};

export const sendResetLink = () => {
  return (dispatch, getState) => {
    API.sendResetLink(getState().login.resetPasswordEmail).then(() => {
      dispatch(openModal("resetLinkSent"));
    });
  };
};

export const passwordReset = token => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      API.resetPassword(
        token,
        getState().login.newPassword,
        getState().login.newPasswordConfirm
      )
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error.response.data.message);
        });
    });
  };
};

export const setStoreInfo = storeInfo => {
  return {
    type: types.SET_STORE_INFO,
    storeInfo,
  };
};
