import { setStepCompleted, setStepsNotCompleted } from "actions/breadcrumb";
import {
  addFlashMessageAction,
  clearAllFlashMessagesAction,
  TYPES,
} from "actions/flashMessages";
import { closeModal, openModal } from "actions/modals";
import { loadRegister } from "actions/register";
import CancelCheckoutLink from "components/checkout/CancelCheckoutLink";
import { AddressLine1Input } from "components/form";
import { Button, Header, Theme } from "components/uikit";
import { TextInput, ZipInput } from "components/uikit/Form";
import states from "data/states";
import { isAddressRequired } from "helpers/cart";
import {
  addDashes,
  isNotPOBox,
  notEmpty,
  validateAddress,
  validateEmail,
  validatePhone,
  validateZip,
} from "helpers/forms";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, Control, Errors, Form } from "react-redux-form";
import styled, { css } from "styled-components";
import CustomerEmailInput from "./CustomerEmailInput";

const Container = styled.div`
  margin: 0 4rem 2rem 4rem;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2.25rem;
`;

const InputRow = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const InputCell = styled.div`
  flex: 1;
  margin-right: 0.75rem;
  ${props =>
    props.invalid &&
    css`
      label {
        color: ${Theme.COLORS.redElement};
      }
      input,
      .select {
        border: 1px solid ${Theme.COLORS.redElement};
      }
    `};
`;

const FORM_MODEL = "customerInfo";
const FORM_NAME = "customerInfoForm";

const CustomerInfoContainer = ({ history }) => {
  const dispatch = useDispatch();
  const customerInfoForm = useSelector(state => state[FORM_NAME]);
  const formValid = customerInfoForm.$form.valid;
  const lineItems = useSelector(state => state.lineItems);
  const addressRequired = isAddressRequired(lineItems);

  useEffect(() => {
    return () => dispatch(clearAllFlashMessagesAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setStepsNotCompleted(["contact", "shipping", "review"]));
  }, [dispatch]);

  useEffect(() => {
    if (!formValid) {
      const message = {
        type: TYPES.error,
        text: "Please complete the marked fields below to continue.",
      };
      dispatch(addFlashMessageAction(message));
    }
  }, [dispatch, formValid]);

  const nextStep = () => history.push("/checkout/delivery");

  const validateAndSubmit = () => {
    dispatch(actions.resetValidity(FORM_MODEL));
    dispatch(actions.validateFields(FORM_MODEL, validators));
    dispatch(actions.submit(FORM_MODEL));
  };

  const handleFormSubmit = () => {
    dispatch(openModal("processing"));
    dispatch(clearAllFlashMessagesAction());
    dispatch(loadRegister()).then(({ validAddress }) => {
      dispatch(closeModal());

      if (addressRequired && !validAddress) {
        dispatch(actions.setErrors(FORM_MODEL, { validAddress: false }));
        dispatch(actions.setValidity(FORM_MODEL, { validAddress: false }));
        dispatch(openModal("invalidAddress"));
      } else {
        nextStep();
        dispatch(setStepCompleted("contact"));
      }
    });
  };

  const fillAddress = address => {
    dispatch(actions.merge(FORM_MODEL, address));
  };

  const isInvalid = fieldName => !customerInfoForm[fieldName].valid;

  let validators = {
    firstname: { required: notEmpty },
    lastname: { required: notEmpty },
    email: {
      required: notEmpty,
      validEmailFormat: validateEmail,
    },
  };
  if (addressRequired) {
    validators = {
      ...validators,
      line1: {
        required: notEmpty,
        validAddressFormat: validateAddress,
        poBox: isNotPOBox,
      },
      city: { required: notEmpty },
      state: { required: notEmpty },
      zip: {
        required: notEmpty,
        validZipFormat: validateZip,
      },
      phone: {
        required: notEmpty,
        isValidPhone: validatePhone,
      },
    };
  }

  return (
    <Container id="customer_info">
      <Header.H2 center>Customer Information</Header.H2>
      <Form
        model={FORM_MODEL}
        onSubmit={handleFormSubmit}
        validateOn="submit"
        validators={validators}
        autoComplete="off"
      >
        <InputRow>
          <InputCell>
            <CustomerEmailInput formName={FORM_NAME} formModel={FORM_MODEL} />
          </InputCell>
        </InputRow>
        <InputRow>
          <InputCell invalid={isInvalid("firstname")}>
            <label htmlFor="firstname">First Name</label>
            <Control.text
              model=".firstname"
              dynamic={false}
              id="firstname"
              name="firstname"
              component={TextInput}
            />
            <Errors
              className="error"
              model=".firstname"
              messages={{ required: "Enter a first name" }}
              wrapper="p"
            />
          </InputCell>
          <InputCell invalid={isInvalid("lastname")}>
            <label htmlFor="lastname">Last Name</label>
            <Control.text
              model=".lastname"
              dynamic={false}
              id="lastname"
              name="lastname"
              component={TextInput}
            />
            <Errors
              className="error"
              model=".lastname"
              messages={{ required: "Enter a last name" }}
              wrapper="p"
            />
          </InputCell>
        </InputRow>
        {addressRequired && (
          <>
            <InputRow>
              <InputCell invalid={isInvalid("line1")}>
                <label htmlFor="line1">Address</label>
                <AddressLine1Input
                  id="line1"
                  name="line1"
                  onAddressSelected={fillAddress}
                />
                <Errors
                  className="error"
                  model=".line1"
                  messages={{
                    required: "Enter a valid address (ie: 123 Mint Way)",
                    validAddressFormat: "Enter a valid address (ie: 123 Mint Way)",
                    poBox: "Sorry! We are unable to ship to PO Boxes at this time.",
                  }}
                  wrapper="p"
                />
                <Errors
                  className="error"
                  model="customerInfo"
                  messages={{
                    validAddress: "This doesn't appear to be a valid address",
                  }}
                  wrapper="p"
                />
              </InputCell>
              <InputCell>
                <label htmlFor="line2">Apt/Suite # (optional)</label>
                <Control.text
                  model=".line2"
                  dynamic={false}
                  id="line2"
                  name="line2"
                  component={TextInput}
                />
              </InputCell>
            </InputRow>
            <InputRow>
              <InputCell invalid={isInvalid("city")}>
                <label htmlFor="city">City</label>
                <Control.text
                  model=".city"
                  dynamic={false}
                  id="city"
                  name="city"
                  component={TextInput}
                />
                <Errors
                  className="error"
                  model=".city"
                  messages={{
                    required: "Enter a valid city name",
                  }}
                  wrapper="p"
                />
              </InputCell>
            </InputRow>
            <InputRow>
              <InputCell invalid={isInvalid("state")}>
                <label htmlFor="state">State</label>
                <div className="select">
                  <Control.select
                    model=".state"
                    dynamic={false}
                    id="state"
                    name="state"
                  >
                    <option value="">State</option>
                    {states.US.map(state => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </Control.select>
                </div>
                <Errors
                  className="error"
                  model=".state"
                  messages={{
                    required: "Please enter your state",
                  }}
                  wrapper="p"
                />
              </InputCell>
              <InputCell invalid={isInvalid("zip")}>
                <label htmlFor="zip">Zipcode</label>
                <Control.text
                  model=".zip"
                  dynamic={false}
                  id="zip"
                  name="zip"
                  component={ZipInput}
                />
                <Errors
                  className="error"
                  model=".zip"
                  messages={{
                    required: "Please enter your ZIP code",
                    validZipFormat: "Please enter valid ZIP code",
                  }}
                  wrapper="p"
                />
              </InputCell>
            </InputRow>
            <InputRow>
              <InputCell invalid={isInvalid("phone")}>
                <label htmlFor="phone">Phone</label>
                <Control.text
                  model=".phone"
                  dynamic={false}
                  id="phone"
                  name="phone"
                  type="tel"
                  parser={addDashes}
                />
                <Errors
                  className="error"
                  model=".phone"
                  messages={{
                    required: "Enter a valid 10-digit number",
                    isValidPhone: "Enter a valid 10-digit number",
                  }}
                  wrapper="p"
                />
              </InputCell>
            </InputRow>
          </>
        )}
        <ActionContainer>
          <Button wider onClick={validateAndSubmit}>
            Next: Delivery
          </Button>
        </ActionContainer>
      </Form>
      <CancelCheckoutLink />
    </Container>
  );
};

export default CustomerInfoContainer;
