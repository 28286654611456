import { Body } from "components/uikit";
import { COLORS } from "components/uikit/Theme";
import React, { useState } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as DecrementIcon } from "svgs/quantityDecrement.svg";
import { ReactComponent as IncrementIcon } from "svgs/quantityIncrement.svg";

const active = props =>
  props.active &&
  css`
    border: 2px solid ${COLORS.graphite};
    svg {
      fill: ${COLORS.graphite};
    }
  `;

const iconDisabled = props =>
  props.iconDisabled &&
  css`
    cursor: inherit;
    svg {
      fill: ${COLORS.sand};
    }
  `;

const Container = styled.div`
  background-color: ${COLORS.ultraLightGray};
  padding: 1px;
  display: flex;
  height: 3rem;
  width: 5.3rem;
  border: 1px solid ${COLORS.mediumGray};
  box-sizing: border-box;
  svg {
    fill: ${COLORS.mediumGray};
  }
  ${active}
`;

const IconContainer = styled.button`
  background-color: ${COLORS.ultraLightGray};
  flex: 1.25;
  display: flex;
  border: none;
  padding: 0;
  outline: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${iconDisabled}
`;

const Value = styled(Body.P)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border: none;
`;

const QuantitySelector = ({ quantity, increment, decrement }) => {
  const [active, setActive] = useState(false);
  const allowDecrement = quantity > 1;

  return (
    <Container
      active={active}
      onFocus={() => setActive(true)}
      onBlur={() => setActive(false)}
    >
      <IconContainer
        data-testid="quantity-decrement"
        onClick={() => allowDecrement && decrement()}
        iconDisabled={!allowDecrement}
      >
        <DecrementIcon />
      </IconContainer>
      <Value data-testid="quantity-value">{quantity}</Value>
      <IconContainer data-testid="quantity-increment" onClick={increment}>
        <IncrementIcon />
      </IconContainer>
    </Container>
  );
};

export default QuantitySelector;
