import { clearAllFlashMessagesAction } from "actions/flashMessages";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import CircleMintCheck from "svgs/CircleMintCheck";

const CompletionIndicator = ({ number, isCompleted }) => {
  return isCompleted ? (
    <CircleMintCheck />
  ) : (
    <span className="number">{number}</span>
  );
};

const Breadcrumb = () => {
  const dispatch = useDispatch();
  const breadcrumb = useSelector(state => state.breadcrumb);

  const handleNavlinkClick = (event, step) => {
    if (step.completed) {
      dispatch(clearAllFlashMessagesAction());
    } else {
      event.preventDefault();
    }
  };

  const steps = [
    {
      name: "Contact",
      path: "/checkout/customer_info",
      stepState: breadcrumb.contact,
    },
    {
      name: "Delivery",
      path: "/checkout/delivery",
      stepState: breadcrumb.shipping,
    },
    { name: "Review", path: "/checkout/review", stepState: breadcrumb.review },
    {
      name: "Payment",
      path: "/checkout/payment",
      stepState: breadcrumb.payment,
    },
  ];

  return (
    <div id="breadcrumb">
      {steps.map((step, i) => (
        <NavLink
          key={i}
          onClick={e => handleNavlinkClick(e, step.stepState)}
          to={step.path}
        >
          <CompletionIndicator
            number={i + 1}
            isCompleted={step.stepState.completed}
          />
          <span>{step.name}</span>
        </NavLink>
      ))}
    </div>
  );
};

export default Breadcrumb;
