import { updateLineItems } from "actions/lineItems";
import * as API from "api";
import { buildCustomer, buildItems } from "helpers/register";
import * as types from "./types";

export const loadRegister = (loadRegisterWithStoreInfo) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const {
        customerInfo,
        lineItems,
        storeInfo: {
          storeName,
          storePhone,
          storeAddress,
          storeKey
        },
        promotionKeys,
        register,
      } = getState();
      const formattedStoreInfo = {
        ...storeAddress,
        firstname: storeName.split(" - ")[0],
        lastname: storeName.split(" - ")[1],
        phone: storePhone,
        email: "retailcheckout@tn.com",
        line1: storeAddress.line_1,
        line2: storeAddress.line_2
      }

      const customer = loadRegisterWithStoreInfo ? buildCustomer(formattedStoreInfo) : buildCustomer(customerInfo) || {};
      const items = buildItems(lineItems) || [];
      const promos = (promotionKeys && promotionKeys.promos) || [];
      const promotion_keys = promos.map(promo => promo.value);
      const purchase_transaction_key = register.purchase_transaction_key || "";

      return API.loadRegister(
        {
          purchase_transaction_key,
          promotion_keys,
          customer,
          items,
        },
        storeKey
      )
        .then(({ data }) => {
          const { valid_address: validAddress } = data;
          dispatch(updateRegister(data));
          resolve({ validAddress });
        })
        .catch(() => {
          reject();
        });
    });
  };
};

const updateRegister = data => {
  // exclude DeliveryServiceFee line item since it's not needed in the app and
  // can cause issues with react-redux-form because of all the index based fields
  const { items, ...register } = data;
  const lineItems = items.filter(
    item => item.costable_type !== "DeliveryServiceFee"
  );

  return dispatch => {
    dispatch(updateLineItems(lineItems));
    dispatch({
      type: types.UPDATE_REGISTER,
      register,
    });
  };
};
