import { login } from "actions/auth";
import { closeModal, openModal } from "actions/modals";
import LoginForm from "components/LoginForm";
import Router from "components/Router";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "react-redux-form";
import { Redirect, Route } from "react-router-dom";

const LoginContainer = ({ location, history }) => {
  const dispatch = useDispatch();
  const loginParams = useSelector(state => state.login);
  const loginFormValid = useSelector(state => state.loginForm.$form.valid);
  const passwordError = useSelector(state => state.loginForm.password.errors);

  const handleFormSubmit = () => {
    dispatch(openModal("processing"));
    dispatch(login(loginParams))
      .then(() => {
        history.push("/");
      })
      .catch(errorMessage => {
        dispatch(closeModal());
        dispatch(actions.setErrors("login.password", errorMessage));
      });
  };

  const handleResetClick = () => {
    history.push("/reset_password");
  };

  const checkResetPasswordSuccess = () =>
    location.pathname.includes("password_reset_successful") ? (
      <div className="successBanner">
        <span>Your password has been reset. Please sign in.</span>
      </div>
    ) : null;

  const getErrorBannerMessage = () => {
    if (typeof passwordError !== "string") {
      return "Please complete the marked fields below to continue.";
    } else if (passwordError.includes("expired")) {
      return "Your password has expired. Please reset your password.";
    } else {
      return "Please complete the marked fields below to continue.";
    }
  };

  return (
    <Router>
      <main>
        <Route
          path="/login"
          render={() => (
            <div>
              <div id="login">
                {loginFormValid ? (
                  checkResetPasswordSuccess()
                ) : (
                  <div className="errorBanner">
                    <span>{getErrorBannerMessage()}</span>
                  </div>
                )}
                <h2>Sign In</h2>
                <LoginForm
                  handleFormSubmit={handleFormSubmit}
                  handleResetClick={handleResetClick}
                />
              </div>
            </div>
          )}
        />
        <Route
          path="/"
          render={props =>
            props.location.pathname === "/login" ? null : (
              <Redirect to="/login" />
            )
          }
        />
      </main>
    </Router>
  );
};

export default LoginContainer;
