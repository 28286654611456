import { Body } from "components/uikit";
import { FONTS } from "components/uikit/Theme";
import { centToDollar } from "helpers/general";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.625rem;
`;

const Divider = styled.div`
  border-top: 1px solid #d8d8d8;
  margin-bottom: 0.625rem;
`;

const Discount = styled(Body.PSmall)`
  font-family: ${FONTS.bold};
`;

const OrderSummary = ({ orderSummary }) => {
  return (
    <Container>
      <Row data-testid="items-total">
        <Body.PSmall>Items Total</Body.PSmall>
        <Body.PSmall>${centToDollar(orderSummary.receiptSubtotal)}</Body.PSmall>
      </Row>
      {orderSummary.receiptDiscount > 0 && (
        <Row data-testid="discount-total">
          <Discount>Discount</Discount>
          <Discount>-${centToDollar(orderSummary.receiptDiscount)}</Discount>
        </Row>
      )}
      <Row data-testid="shipping-total">
        <Body.PSmall>Shipping Total</Body.PSmall>
        <Body.PSmall>${centToDollar(orderSummary.shippingCost)}</Body.PSmall>
      </Row>
      {orderSummary.recyclingFeeAmount > 0 && (
        <Row data-testid="recycling-total">
          <Body.PSmall>Recycling Fee</Body.PSmall>
          <Body.PSmall>
            ${centToDollar(orderSummary.recyclingFeeAmount)}
          </Body.PSmall>
        </Row>
      )}
      <Row data-testid="tax-total">
        <Body.PSmall>Tax</Body.PSmall>
        <Body.PSmall>
          ${centToDollar(orderSummary.chargedTaxAmount)}
        </Body.PSmall>
      </Row>
      <Divider />
      <Row data-testid="total">
        <Body.PSmall>Total</Body.PSmall>
        <Body.PSmall>${centToDollar(orderSummary.orderTotal)}</Body.PSmall>
      </Row>
    </Container>
  );
};

export default OrderSummary;
