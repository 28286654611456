import {
  FREIGHT,
  SAME_DAY,
  STANDARD_GROUND,
  STORE_PICKUP,
  WHITE_GLOVE,
} from "data/deliveryMethods";
import { productImage } from "helpers/products";
import React from "react";
import { Control } from "react-redux-form";
import SameDayDelivery from "./deliveryOptions/SameDayDelivery";
import StandardDelivery from "./deliveryOptions/StandardDelivery";
import WhiteGloveDelivery from "./deliveryOptions/WhiteGloveDelivery";

const DeliveryMethodDetail = props => {
  const {
    deliveryOption,
    handleSameDayModalClick,
    selectedDeliveryMethods,
    requestedDeliveryDates,
    deliveryNumber,
  } = props;

  switch (deliveryOption.name) {
    case STORE_PICKUP:
      return <StorePickUpOption />;
    case STANDARD_GROUND:
    case FREIGHT:
      return (
        <StandardDelivery
          estimatedDeliveryRange={deliveryOption.estimated_delivery_range}
          requestedDeliveryDate={requestedDeliveryDates[deliveryNumber - 1]}
        />
      );
    case SAME_DAY:
      return (
        <SameDayDelivery
          estimatedDelivery={deliveryOption.estimated_delivery}
          handleSameDayModalClick={handleSameDayModalClick}
          deliveryPrice={deliveryOption.price}
        />
      );
    case WHITE_GLOVE: {
      const firstSelected = selectedDeliveryMethods.indexOf(WHITE_GLOVE);
      const hasWhiteGloveSelected = firstSelected >= 0;

      return (
        <WhiteGloveDelivery
          priceIncluded={
            hasWhiteGloveSelected && firstSelected !== deliveryNumber - 1
          }
          pricePerOrder={deliveryOption.price_per_order}
        />
      );
    }
    default:
      break;
  }
};

const DeliveryOption = props => {
  const {
    deliveryOption,
    handleSameDayModalClick,
    deliveryNumber,
    selectedDeliveryMethods,
    requestedDeliveryDates,
  } = props;
  const optionName = deliveryOption.name;
  const radioName = `[${deliveryNumber - 1}].delivery_method`;
  const radioId = `${radioName}_${optionName}`;

  return (
    <li data-testid="delivery-option">
      <Control.radio
        name={radioName}
        id={radioId}
        value={optionName}
        dynamic={false}
        model={`.${radioName}`}
      />
      <label htmlFor={radioId}>
        <DeliveryMethodDetail
          deliveryOption={deliveryOption}
          handleSameDayModalClick={handleSameDayModalClick}
          selectedDeliveryMethods={selectedDeliveryMethods}
          requestedDeliveryDates={requestedDeliveryDates}
          deliveryNumber={deliveryNumber}
        />
      </label>
    </li>
  );
};

const StorePickUpOption = () => (
  <div className="description">
    <p>Pick up in Store - Free</p>
    <p className="estimate">Get it today</p>
  </div>
);

const Delivery = props => {
  const {
    registerItem,
    deliveriesQuantity,
    deliveryNumber,
    handleSameDayModalClick,
    product,
    selectedDeliveryMethods,
    requestedDeliveryDates,
  } = props;

  if (!product) return null;

  return (
    <div className="delivery" data-testid="delivery-item">
      <div className="delivery_header">
        <div className="header_text">
          <h5>
            Item {deliveryNumber} of {deliveriesQuantity}
          </h5>
          <p>{product.name}</p>
        </div>
        <img src={productImage(product)} alt="" />
      </div>
      <div className="delivery_options">
        <ul>
          {registerItem.delivery_options.map((deliveryOption, index) => (
            <DeliveryOption
              key={index}
              deliveriesQuantity={deliveriesQuantity}
              deliveryNumber={deliveryNumber}
              deliveryOption={deliveryOption}
              handleSameDayModalClick={handleSameDayModalClick}
              selectedDeliveryMethods={selectedDeliveryMethods}
              requestedDeliveryDates={requestedDeliveryDates}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Delivery;
