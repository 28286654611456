import _ from "underscore";
import React, { Component } from "react";
import {
  setSourceCategoryId,
  setSourceSubcategory,
  submitSourceAttribution,
  setSavedSourceAttribution,
  resetSourceAttributionUI,
} from "../actions/sourceAttribution";
import { connect } from "react-redux";
import Sources from "../components/sourceAttribution/Sources";
import SourceSvgs from "../svgs/Sources";

class SourceAttributionContainer extends Component {
  constructor(props) {
    super(props);
    this.handleSourceClick = this.handleSourceClick.bind(this);
    this.handleSkipClick = this.handleSkipClick.bind(this);
  }

  handleSourceClick(source, e) {
    e.preventDefault();

    if (source.id && !this.props.selectedCategoryId) {
      // Category click
      this.props.dispatch(setSourceCategoryId(source.id));
    } else {
      // Subcategory click
      this.props.dispatch(setSourceSubcategory(source.name));
    }

    if (!source.children || source.children.length < 1) {
      this.props.dispatch(submitSourceAttribution()).then(() => {
        this.props.dispatch(
          setSavedSourceAttribution(
            this.props.selectedCategoryId,
            this.props.selectedSubcategoryName
          )
        );
        this.props.dispatch(resetSourceAttributionUI());
        this.props.history.push("/receipt");
      });
    }
  }

  handleSkipClick(e) {
    e.preventDefault();
    this.props.dispatch(resetSourceAttributionUI());
    this.props.history.push("receipt");
  }

  selectedSourceCategory() {
    return _(this.props.sources).find(
      s => s.id === this.props.selectedCategoryId
    );
  }

  subCategories() {
    let source = this.selectedSourceCategory();
    if (!(source && source.children)) {
      return [];
    }
    return source.children;
  }

  sources() {
    let subSources = this.subCategories();

    if (subSources.length > 0) {
      return subSources;
    } else {
      return this.props.sources;
    }
  }

  render() {
    if (this.props.sources.length < 1) {
      this.props.history.push("/receipt");
      return false;
    }

    return (
      <section id="source_attribution">
        <SourceSvgs />
        <h4>
          {this.subCategories().length > 0
            ? this.selectedSourceCategory().selected_copy
            : "How did you first hear about Tuft & Needle?"}
        </h4>
        <p>
          {this.subCategories().length > 0 &&
          this.selectedSourceCategory().subtext
            ? this.selectedSourceCategory().subtext
            : "We'd love to know!"}
        </p>
        <Sources
          sources={this.sources()}
          handleSourceClick={this.handleSourceClick}
        />
        <a
          onClick={this.handleSkipClick}
          className="more underline_button"
          href="#/"
        >
          Nevermind
        </a>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedCategoryId: state.sourceAttributions.selectedCategoryId,
    selectedSubcategoryName: state.sourceAttributions.selectedSubcategoryName,
    sources: state.sourceAttributions.sources,
  };
}

export default connect(mapStateToProps)(SourceAttributionContainer);
