import { useRect } from "@reach/rect";
import { openModal } from "actions/modals";
import FlashMessages from "components/FlashMessages";
import { Body, Link, Theme } from "components/uikit";
import { logout } from "helpers/login";
import React, { useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link as LinkRouter } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Cart } from "svgs/cart.svg";
import { ReactComponent as LogoHorizontal } from "svgs/logoHorizontal.svg";

const logoOnlyPages = [
  "/receipt",
  "/login",
  "/reset_password",
  "/retail/password_resets",
];

const FixedContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 99;
`;

const HeaderContainer = styled.header`
  border-bottom: 1px solid ${Theme.COLORS.lightGray};
  display: flex;
  padding: 0.5rem 2rem;
  align-items: center;
`;

const LogoContainer = styled.a`
  flex: 1;
  display: flex;
  justify-content: center;
  svg {
    height: 1rem;
  }
`;

const LeftContainer = styled.div`
  flex: 2;
`;
const RightContainer = styled.div`
  flex: 2;
  display: flex;
  justify-content: flex-end;
`;
const CartLink = styled(LinkRouter)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;
const PageTopPadding = styled.div`
  height: ${props => props.height}px;
  width: 100%;
`;

const CartSizeBadge = styled.p`
  background-color: ${Theme.COLORS.blueElement};
  color: white;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  margin-left: 0.5rem;
`;

const PrintOnly = styled(Body.PSmall)`
  display: none;
  @media print {
    display: block;
  }
`;

const useHeight = () => {
  const ref = useRef();
  const rect = useRect(ref);
  const height = rect ? rect.height : 0;

  return { ref, height };
};

const Header = ({
  storeName,
  storeHours,
  storePhone,
  userName,
  cartSize,
  location,
  history,
  dispatch,
}) => {
  const { ref, height } = useHeight();

  const handleLogoClick = e => {
    e.preventDefault();
    const { pathname } = location;

    if (pathname.match(/checkout/)) {
      dispatch(openModal("exitCheckout"));
    } else {
      history.push("/");
    }
  };

  const handleLogoutClick = e => {
    e.preventDefault();
    logout();
  };

  const showUserInfo = !logoOnlyPages.includes(location.pathname);
  const showCart = showUserInfo && !location.pathname.includes("/checkout");
  const pageIsCart = location.pathname.includes("/cart");

  return (
    <>
      <FixedContainer ref={ref}>
        <HeaderContainer>
          <LeftContainer>
            {showUserInfo && (
              <>
                <Body.PSmall light>
                  {storeName || "Store"} - {userName || "User"}
                </Body.PSmall>
                {pageIsCart && storeHours &&
                  <PrintOnly light>
                    {storeHours.join(", ")}
                  </PrintOnly>
                }
                {pageIsCart && storePhone &&
                  <PrintOnly light>
                    {storePhone}
                  </PrintOnly>
                }
                <Link primary small light onClick={handleLogoutClick}>
                  Logout
                </Link>
              </>
            )}
          </LeftContainer>
          <LogoContainer
            onClick={handleLogoClick}
            href="#/"
            data-testid="header-logo"
          >
            <LogoHorizontal />
          </LogoContainer>
          <RightContainer>
            {showCart && (
              <>
                <CartLink to="/cart">
                  <Cart data-testid="cart-svg" />
                </CartLink>
                {cartSize > 0 && (
                  <CartLink to="/cart">
                    <CartSizeBadge data-testid="cart-size-badge">
                      {cartSize}
                    </CartSizeBadge>
                  </CartLink>
                )}
              </>
            )}
          </RightContainer>
        </HeaderContainer>
        <FlashMessages />
      </FixedContainer>
      <PageTopPadding height={height} />
    </>
  );
};

function mapStateToProps(state) {
  return {
    storeName: state.storeInfo.storeName,
    storeHours: state.storeInfo.storeHours,
    storePhone: state.storeInfo.storePhone,
    userName: state.storeInfo.userName,
    cartSize: state.lineItems.length,
  };
}

export default withRouter(connect(mapStateToProps)(Header));
