import { openModal } from "actions/modals";
import { Link } from "components/uikit";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.75rem;
`;

const CancelCheckoutLink = () => {
  const dispatch = useDispatch();

  const handleCancelClick = e => {
    e.preventDefault();
    dispatch(openModal("exitCheckout"));
  };

  return (
    <ActionContainer>
      <Link primary onClick={handleCancelClick}>
        Cancel
      </Link>
    </ActionContainer>
  );
};

export default CancelCheckoutLink;
