import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Control, Errors, actions } from "react-redux-form";
import { cancelOrder } from "../../actions/order";
import { notEmpty, validateEmail } from "../../helpers/forms";

class CancelOrderModal extends Component {
  constructor(props) {
    super(props);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(actions.change("cancelOrder.slug", this.props.slug));
  }

  handleFormSubmit() {
    this.props.dispatch(cancelOrder());
  }

  addClass(fieldName) {
    return this.props.cancelOrderForm[fieldName].valid
      ? "field_wrap"
      : "field_wrap invalid";
  }

  render() {
    return (
      <Form
        id="cancel_order_modal"
        model="cancelOrder"
        onSubmit={this.handleFormSubmit}
        validateOn="submit"
        validators={{
          reasonId: { required: notEmpty },
          email: {
            required: notEmpty,
            validEmailFormat: validateEmail,
          },
          reasonNote: {
            required: notEmpty,
          },
        }}
      >
        <h3>Cancel your order</h3>
        <p>Order #{this.props.orderNumber}</p>
        <Control.text
          model=".slug"
          name="slug"
          type="hidden"
          style={{ display: "none" }}
        />
        <div className={this.addClass("reasonId")}>
          <label htmlFor="reason">Reason</label>
          <div className="select_wrap">
            <div className="select">
              <Control.select model=".reasonId" name="reasonId">
                <option value="">Select a reason...</option>
                <option value="27">I changed my mind</option>
                <option value="29">Order is taking too long to deliver</option>
                <option value="31">
                  I accidentally ordered too many or too few items
                </option>
                <option value="28">
                  I accidentally ordered the wrong size
                </option>
                <option value="79">Other</option>
              </Control.select>
            </div>
          </div>
          <Errors
            className="error"
            model=".reasonId"
            messages={{ required: "A reason for canceling is required." }}
            wrapper="p"
          />
        </div>
        <div className={this.addClass("email")}>
          <label htmlFor="email">Email address</label>
          <Control.text
            model=".email"
            id="email"
            name="email"
            type="email"
            className="email"
          />
          <Errors
            className="error"
            model=".email"
            messages={{
              required:
                "Please provide the email used to purchase these items.",
            }}
            wrapper="p"
          />
        </div>
        <div className={this.addClass("reasonNote")}>
          <label htmlFor="notes">Additional notes</label>
          <Control.textarea
            model=".reasonNote"
            name="reasonNote"
            placeholder="Required"
            rows="6"
          >
            Required
          </Control.textarea>
          <Errors
            className="error"
            model=".email"
            messages={{
              required:
                "Please provide us with additional notes to understand why you are canceling.",
            }}
            wrapper="p"
          />
        </div>
        <Errors
          className="error"
          model="cancelOrder"
          messages={{
            processible: "We failed to process your request, please try again.",
          }}
          wrapper="p"
        />
        <input
          type="submit"
          className="primary_button"
          value="Cancel My Order"
        />
        <a
          className="underline_button"
          onClick={this.props.handleCloseModalClick}
          href="#/"
        >
          Go Back
        </a>
      </Form>
    );
  }
}

function mapStateToProps(state) {
  return {
    slug: state.order.receipt.slug,
    orderNumber: state.order.receipt.number,
    cancelOrderForm: state.cancelOrderForm,
  };
}

export default connect(mapStateToProps)(CancelOrderModal);
