import { modelReducer, formReducer } from "react-redux-form";

const initialState = {
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  line1: "",
  line2: "",
  city: "",
  state: "",
  zip: "",
  addressVerified: false,
};

export const customerInfo = modelReducer("customerInfo", initialState);
export const customerInfoForm = formReducer("customerInfo", initialState);
