import * as Body from "components/uikit/Body";
import { COLORS, FONTS } from "components/uikit/Theme";
import React from "react";
import styled, { css } from "styled-components";
import { ReactComponent as CheckSvgIcon } from "svgs/check.svg";
import { ReactComponent as WarningSvgIcon } from "svgs/warning.svg";

export const STATUS = {
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
};

const STATUS_COLORS = {
  [STATUS.SUCCESS]: COLORS.greenElement,
  [STATUS.WARNING]: COLORS.yellowElement,
  [STATUS.ERROR]: COLORS.redElement,
};
const STATUS_ICONS = {
  [STATUS.SUCCESS]: CheckSvgIcon,
  [STATUS.WARNING]: WarningSvgIcon,
  [STATUS.ERROR]: WarningSvgIcon,
};

export const inputCss = css`
  font-family: ${FONTS.regular};
  width: 100%;
  height: 3rem;
  padding: 0.9375rem 0.75rem;
  font-size: 1rem;
  color: inherit;
  border: 1px solid ${COLORS.lightGray};
  background-color: ${COLORS.ultraLightGray};
  box-sizing: border-box;

  &:focus {
    border: 2px solid ${COLORS.graphite};
    outline: none;
  }
`;

const Input = styled.input`
  ${inputCss}
  ${props =>
    props.status && {
      border: `1px solid ${STATUS_COLORS[props.status]}`,
    }}
  &:focus {
    ${props =>
    props.status && { border: `2px solid ${STATUS_COLORS[props.status]}` }}
  }
`;
Input.defaultProps = {
  type: "text",
};

export const TextInput = styled(Input).attrs({ type: "text" })``;

export const ZipInput = styled(TextInput).attrs({
  pattern: "[0-9-]*",
  inputMode: "numeric",
})``;

const InputLabel = styled(Body.P)`
  ${props => props.status && { color: STATUS_COLORS[props.status] }}
`;
const InputRow = styled.div`
  margin-top: 0.5rem;
`;
const InputMessageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
`;
const InputMessage = styled(Body.P).attrs({ size: "small" })`
  ${props => props.status && { color: STATUS_COLORS[props.status] }}
  margin-left: 0.5rem;
`;

export const InputContainer = ({ label, message, status, ...props }) => {
  const Icon = status && STATUS_ICONS[status];

  return (
    <label>
      {label && <InputLabel status={status}>{label}</InputLabel>}
      <InputRow>
        <Input status={status} {...props} />
      </InputRow>
      {message && (
        <InputMessageContainer>
          {Icon && <Icon fill={STATUS_COLORS[status]} />}
          <InputMessage status={status}>{message}</InputMessage>
        </InputMessageContainer>
      )}
    </label>
  );
};
