export const centToDollar = dec => {
  return (dec / 100).toFixed(2);
};

export const centToDollarNoDecimal = dec => {
  return dec / 100;
};

export const formatMoney = money => `$${centToDollarNoDecimal(money)}`;

export const normalizeColorName = string => {
  return string ? string.replace(/[_\s/]/g, "-").toLowerCase() : "";
};

const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

export const humanize = str =>
  str &&
  str
    .replace(/\W|_/g, " ")
    .trim()
    .split(" ")
    .filter(str => str.trim())
    .map(capitalize)
    .join(" ");
