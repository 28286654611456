import * as types from "./types";

export const setStepCompleted = step => {
  return {
    type: types.SET_STEP_COMPLETED,
    step,
  };
};

export const setStepsNotCompleted = steps => {
  return dispatch => {
    steps.forEach(step => {
      dispatch({
        type: types.SET_STEP_NOT_COMPLETED,
        step,
      });
    });
  };
};

export const resetBreadcrumb = () => {
  return {
    type: types.RESET_BREADCRUMB,
  };
};
