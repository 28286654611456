import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Control, Errors } from "react-redux-form";
import { sendResetLink } from "../actions/auth";
import { notEmpty, validateEmail } from "../helpers/forms";

class SendResetLinkForm extends Component {
  constructor(props) {
    super(props);
    this.handleSendResetLinkClick = this.handleSendResetLinkClick.bind(this);
  }

  addInvalidClass() {
    return this.props.emailValid ? "" : "invalid";
  }

  handleSendResetLinkClick() {
    this.props.dispatch(sendResetLink());
  }

  render() {
    return (
      <div id="password_reset">
        <h2>Reset Password</h2>
        <Form
          model="login"
          onSubmit={this.handleSendResetLinkClick}
          validateOn="submit"
          validators={{
            resetPasswordEmail: {
              required: notEmpty,
              validEmailFormat: validateEmail,
            },
          }}
        >
          <dl className={this.addInvalidClass("resetPasswordEmail")}>
            <dt>
              <label htmlFor="email">Email</label>
            </dt>
            <dd>
              <Control.text
                model=".resetPasswordEmail"
                dynamic={false}
                id="email"
                name="email"
                type="email"
                className="email"
              />
              <Errors
                className="error"
                model=".resetPasswordEmail"
                messages={{
                  required: "Enter your email",
                  validEmailFormat: "Enter a valid email (ie: mia@tn.com)",
                }}
                wrapper="p"
              />
            </dd>
          </dl>
          <input
            className="primary_button"
            name="sendResetLink"
            type="submit"
            value="Send Reset Link"
          />
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    emailValid: state.loginForm.resetPasswordEmail.valid,
  };
}

export default connect(mapStateToProps)(SendResetLinkForm);
