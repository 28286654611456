import * as types from "./types";
import * as API from "../api";
import { cartSubtotal, buildCart } from "helpers/cart";
import { setError } from "./errors";

export const applySpendHurdles = () => {
  return (dispatch, getState) => {
    if (!getState().spendHurdle.hurdles) {
      return;
    }
    const cart = buildCart(getState());
    let subtotal = cartSubtotal(cart) * 100,
      hurdles = getState().spendHurdle.hurdles,
      hurdleMet = hurdles.filter(hurdle => {
        return subtotal >= hurdle.options.threshold;
      });

    if (hurdleMet.length > 1) {
      const highestThreshold = hurdleMet.reduce((a, b) => {
        if (typeof a === "number") {
          return Math.max(a, b.options.threshold);
        } else {
          return Math.max(a.options.threshold, b.options.threshold);
        }
      });
      hurdleMet = hurdleMet.filter(hurdle => {
        return hurdle.options.threshold === highestThreshold;
      });
    }

    dispatch({
      type: types.SET_APPLIED_HURDLE,
      appliedHurdle: hurdleMet.length ? hurdleMet[0] : null,
    });
  };
};

export const setSpendHurdle = () => {
  return dispatch => {
    API.getSpendHurdle()
      .then(resp => {
        const hurdles = resp.data.promotions.filter(promo => {
          return promo.type === "SpendHurdlePromotion";
        });
        if (!hurdles) {
          return;
        }
        dispatch({ type: types.SET_SPEND_HURDLE, hurdles });
      })
      .catch(error => {
        if (error.request) {
          dispatch(setError("connectionError"));
        } else {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      });
  };
};
