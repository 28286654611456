import {
  ADD_FLASH_MESSAGE,
  CLEAR_FLASH_MESSAGE,
  CLEAR_ALL_FLASH_MESSAGES,
} from "actions/types";

export const TYPES = {
  error: "error",
  success: "success",
};

export const addFlashMessageAction = message => ({
  type: ADD_FLASH_MESSAGE,
  message,
});

export const clearFlashMessageAction = message => ({
  type: CLEAR_FLASH_MESSAGE,
  message,
});

export const clearAllFlashMessagesAction = () => ({
  type: CLEAR_ALL_FLASH_MESSAGES,
});
