import * as types from "actions/types";
import _ from "underscore";

const initialState = {
  sizeChart: { open: false },
  exitCheckout: { open: false },
  sameDayDelivery: { open: false },
};

export default (state = initialState, action) => {
  let actions = {
    [types.OPEN_MODAL]: () => {
      return {
        ...state,
        [action.modal]: { open: true, payload: action.payload || {} },
      };
    },
    [types.CLOSE_MODAL]: () => {
      const obj = _.mapObject(state, val => {
        val.open = false;
        return val;
      });
      return {
        ...obj,
      };
    },
  };

  if (actions[action.type]) {
    return actions[action.type]();
  }
  return state;
};
