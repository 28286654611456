import { setStepCompleted } from "actions/breadcrumb";
import { closeModal } from "actions/modals";
import { Body, Button, Header, Link } from "components/uikit";
import { formatAddressWithName } from "helpers/address";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { setAddressVerified } from "actions/customerInfo";

const Container = styled.div``;
const Title = styled(Header.H3)`
  margin-bottom: 1rem;
`;
const Content = styled(Body.P)`
  margin-bottom: 1rem;
`;
const Address = styled(Body.P)`
  white-space: pre-line;
  margin-bottom: 2rem;
`;
const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const ConfirmButton = styled(Button)`
  margin-bottom: 1rem;
`;

const InvalidAddressModal = ({ redirect }) => {
  const dispatch = useDispatch();
  const customerInfo = useSelector(state => state["customerInfo"]);
  const formattedAddress = formatAddressWithName(customerInfo);

  const handleEditAddress = () => {
    dispatch(closeModal());
  };

  const handleConfirmInvalidAddress = () => {
    dispatch(closeModal());
    dispatch(setAddressVerified());
    redirect("/checkout/delivery");
    dispatch(setStepCompleted("contact"));
  };

  return (
    <Container data-testid="invalid-address-modal">
      <Title>This doesn&apos;t appear to be a valid address.</Title>
      <Content>
        Please double check your address. If it&apos;s correct, continue to
        delivery and payment.
      </Content>
      <Address bold>{formattedAddress}</Address>
      <ActionsContainer>
        <ConfirmButton onClick={handleEditAddress}>Edit Address</ConfirmButton>
        <Link primary onClick={handleConfirmInvalidAddress}>
          Continue to delivery
        </Link>
      </ActionsContainer>
    </Container>
  );
};

export default InvalidAddressModal;
