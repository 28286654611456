import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { Body, Button, Form, Header } from "components/uikit";
import { COLORS } from "components/uikit/Theme";
import { isValidEmail } from "helpers/forms";
import useDebouncedState from "hooks/useDebouncedState";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, Control, Errors } from "react-redux-form";
import styled from "styled-components";
import { displayAddressSecurely } from "helpers/address";

const AddressList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const BoxContainer = styled.div`
  border: 1px solid ${COLORS.lightGray};
  width: 14.5rem;
  margin-right: 0.75rem;
  margin-top: 0.75rem;
  padding: 1.5rem;
`;

const AddressRow = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
`;

const AddressLabel = styled(Header.H5)`
  width: 4.5rem;
`;

const AddressValue = styled(Body.P)`
  white-space: pre-line;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 1rem;
`;

export const GET_CUSTOMERS = gql`
  query GetCustomers($email: NonEmptyString!) {
    customers: getCustomers(email: $email) {
      id
      shippingAddresses {
        name
        phone
        line1
        line2
        city
        state
        zip
      }
    }
  }
`;

const CustomerEmailInput = ({ formName, formModel }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useDebouncedState();
  const customerInfoForm = useSelector(state => state[formName]);
  const emailFromForm = useSelector(state => state[formModel].email);
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    setEmail(emailFromForm);
  }, [setEmail, emailFromForm]);

  const { data } = useQuery(GET_CUSTOMERS, {
    variables: { email },
    skip: !isValidEmail(email),
  });

  const selectAddress = address => {
    const [firstName, ...lastNames] = address.name.split(" ");
    setShowResults(false);
    dispatch(
      actions.merge(formModel, {
        firstname: firstName,
        lastname: lastNames.join(" "),
        phone: address.phone,
        line1: address.line1,
        line2: address.line2,
        city: address.city,
        state: address.state,
        zip: address.zip,
      })
    );
  };

  const emailStatus = customerInfoForm.email.valid ? null : Form.STATUS.ERROR;
  const message =
    !emailStatus &&
    "* By typing your email, you agree to receive messages from T&N";

  return (
    <>
      <Control.text
        model=".email"
        dynamic={false}
        autoCapitalize="none"
        autoComplete="off"
        autoCorrect="off"
        id="email"
        name="email"
        type="email"
        component={Form.InputContainer}
        label="Email"
        message={message}
        status={emailStatus}
        onFocus={() => setShowResults(true)}
      />
      <Errors
        className="error"
        model=".email"
        messages={{
          required: "Enter a valid email (ie: mia@tn.com)",
          validEmailFormat: "Enter a valid email (ie: mia@tn.com)",
        }}
        wrapper="p"
      />
      <AddressList>
        {data &&
          showResults &&
          data.customers.map(customer =>
            customer.shippingAddresses.map((address, i) => (
              <BoxContainer key={`${customer.id}-${i}`}>
                <AddressRow>
                  <AddressLabel>Name</AddressLabel>
                  <AddressValue>{address.name}</AddressValue>
                </AddressRow>
                <AddressRow>
                  <AddressLabel>Address</AddressLabel>
                  <AddressValue>{displayAddressSecurely(address)}</AddressValue>
                </AddressRow>
                <ActionContainer>
                  <Button secondary onClick={() => selectAddress(address)}>
                    Use this info
                  </Button>
                </ActionContainer>
              </BoxContainer>
            ))
          )}
      </AddressList>
    </>
  );
};

export default CustomerEmailInput;
