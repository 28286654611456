import { addFlashMessageAction, TYPES } from "actions/flashMessages";
import { updateRequestedDeliveryDates } from "actions/lineItems";
import { Body, Button, Header, Link } from "components/uikit";
import { inputCss } from "components/uikit/Form";
import { COLORS } from "components/uikit/Theme";
import moment from "moment";
import React, { useState } from "react";
import { SingleDatePicker } from "react-dates";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import useSelectedProducts from "hooks/useSelectedProducts";

const PageContainer = styled.div`
  width: 100%;
`;
const ItemsContainer = styled.div`
  border: 1px solid ${COLORS.lightGray};
  margin-top: 2.5rem;
  padding: 1.625rem;
`;
const Item = styled(Body.P)`
  margin-bottom: 1rem;
`;
const InputContainer = styled.div`
  .CalendarDay__selected {
    background: ${COLORS.graphite};
    border: 1px double ${COLORS.graphite};
    color: #fff;
  }
  .SingleDatePicker,
  .SingleDatePickerInput {
    display: block;
  }
  .DateInput {
    width: 100%;
  }
  .DateInput_input {
    ${inputCss}
  }
`;
const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2.25rem;
`;
const Instructions = styled(Body.P)`
  margin: 0.5rem 0;
`;

const RequestDeliveryDatesForm = ({ history }) => {
  const dispatch = useDispatch();
  const lineItems = useSelector(state => state.lineItems);
  const products = useSelectedProducts();

  const items = lineItems.map(item => {
    const product = products[item.sku];

    const deliveryOption = item.delivery_options.find(
      ({ name }) => name === item.delivery_method
    );
    const requestableDeliveryDates = deliveryOption
      ? deliveryOption.requestable_delivery_dates
      : [];

    return {
      name: product.name,
      requestedDeliveryDate: item.requested_delivery_date,
      requestableDeliveryDates,
      eligibleToRequestDate: requestableDeliveryDates.length > 0,
    };
  });

  const [focused, setFocused] = useState(false);
  const [requestedDate, setRequestedDate] = useState();

  const eligibleItems = items.filter(
    ({ eligibleToRequestDate }) => eligibleToRequestDate
  );

  // since the current UI is a bulk operation, we select the first eligible item and get is requested date or the first requestable date
  const firstEligibleItem = eligibleItems[0];
  const preSelectedDeliveryDate =
    firstEligibleItem.requestedDeliveryDate ||
    firstEligibleItem.requestableDeliveryDates[0];

  const navigateBack = () => history.push("/checkout/delivery");

  const handleBackClick = e => {
    e.preventDefault();
    navigateBack();
  };

  const applyNewDeliveryDates = e => {
    e.preventDefault();
    const date = requestedDate ? requestedDate.format("YYYY-MM-DD") : null;
    const dates = items.map(item => (item.eligibleToRequestDate ? date : null));

    dispatch(updateRequestedDeliveryDates(dates));
    navigateBack();
    dispatch(
      addFlashMessageAction({
        type: TYPES.success,
        text: "New dates selected.",
      })
    );
  };

  const isOutsideRange = date =>
    !firstEligibleItem.requestableDeliveryDates.includes(
      date.format("YYYY-MM-DD")
    );

  return (
    <PageContainer>
      <Header.H5Uppercase center>Request new delivery dates</Header.H5Uppercase>
      <ItemsContainer>
        {eligibleItems.map((item, i) => (
          <Item key={i}>{item.name}</Item>
        ))}
        <InputContainer data-testid="date-picker">
          <label>
            <Instructions>Pick a new date for the selected items:</Instructions>
            <SingleDatePicker
              date={requestedDate || moment(preSelectedDeliveryDate)}
              onDateChange={setRequestedDate}
              focused={focused}
              onFocusChange={({ focused }) => setFocused(focused)}
              numberOfMonths={1}
              daySize={50}
              isOutsideRange={isOutsideRange}
              readOnly
              hideKeyboardShortcutsPanel
              noBorder
            />
          </label>
        </InputContainer>
      </ItemsContainer>
      <ActionContainer>
        <Button wider onClick={applyNewDeliveryDates}>
          Confirm
        </Button>
      </ActionContainer>
      <ActionContainer>
        <Link primary onClick={handleBackClick}>
          Back
        </Link>
      </ActionContainer>
    </PageContainer>
  );
};

export default RequestDeliveryDatesForm;
