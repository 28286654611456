import React from "react";

const MintCheck = () => {
  return (
    <svg
      width="30px"
      height="28px"
      viewBox="0 0 30 28"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Shape</title>
      <desc>Created with Sketch.</desc>
      <defs></defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Password-Reset-Modal"
          transform="translate(-369.000000, -211.000000)"
          fill="#60EDB3"
        >
          <g id="checkmark" transform="translate(369.000000, 211.000000)">
            <g id="Shape">
              <path d="M29.8557686,3.35416604 C29.9519237,3.54861049 30,3.69444444 30,3.79166667 C30,3.88888889 29.9519237,4.03472284 29.8557686,4.22916729 L11.8990388,27.5625006 C11.6105772,27.854166 11.3942302,28 11.25,28 C11.0096148,28 10.7692308,27.8784725 10.5288468,27.6354164 L0.360577231,17.7187497 L0.144231385,17.5 C0.0480763077,17.3055556 0,17.1597216 0,17.0625006 C0,17.0138889 0.0480763077,16.8923614 0.144231385,16.697917 L0.288461538,16.552083 C1.63461538,15.0937497 2.69230769,13.9756948 3.46153846,13.197917 C3.75,12.9062503 3.94230769,12.7604164 4.03846154,12.7604164 C4.23076923,12.7604164 4.47115323,12.9062503 4.759616,13.197917 L10.5288468,18.8854164 L24.9519237,0.145833956 C25.0480763,0.0486104889 25.1923077,0 25.3846154,0 C25.5288468,0 25.6971151,0.0486104889 25.8894228,0.145833956 L29.8557686,3.35416604 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MintCheck;
