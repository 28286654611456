import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { notEmpty } from "helpers/forms";
import React from "react";
import { useSelector } from "react-redux";
import { Control, Errors, Form } from "react-redux-form";

const GET_STORES = gql`
  query GetStores {
    stores: getRetailStores {
      uuid
      name
    }
  }
`;
const LoginForm = ({ handleFormSubmit, handleResetClick }) => {
  const loginForm = useSelector(state => state.loginForm);

  const { data } = useQuery(GET_STORES);
  const stores = data ? data.stores : [];

  const addInvalidClass = fieldName =>
    loginForm[fieldName].valid ? "" : "invalid";

  return (
    <Form
      model="login"
      onSubmit={handleFormSubmit}
      validateOn="submit"
      validators={{
        store: { required: notEmpty },
        email: { required: notEmpty },
        password: { required: notEmpty },
      }}
    >
      <dl className={addInvalidClass("store")}>
        <dt>
          <label htmlFor="store">Store</label>
        </dt>
        <dd>
          <div className="select">
            <Control.select
              model=".store"
              dynamic={false}
              id="store"
              name="store"
            >
              <option value="">Select store...</option>
              {stores.map(store => (
                <option key={store.uuid} value={store.uuid}>
                  {store.name}
                </option>
              ))}
            </Control.select>
          </div>
          <Errors
            className="error"
            model=".store"
            messages={{ required: "Select a store" }}
            wrapper="p"
          />
        </dd>
      </dl>
      <dl className={addInvalidClass("email")}>
        <dt>
          <label htmlFor="email">Email</label>
        </dt>
        <dd>
          <Control.text
            model=".email"
            dynamic={false}
            id="email"
            name="email"
            type="email"
            className="email"
          />
          <Errors
            className="error"
            model=".email"
            messages={{ required: "Enter your email" }}
            wrapper="p"
          />
        </dd>
      </dl>
      <dl className={addInvalidClass("password")}>
        <dt>
          <label htmlFor="password">Password</label>
        </dt>
        <dd>
          <Control.text
            model=".password"
            dynamic={false}
            id="password"
            name="password"
            type="password"
          />
          <Errors
            className="error"
            model=".password"
            messages={{ required: "Enter your password" }}
            wrapper="p"
          />
        </dd>
      </dl>
      <Errors
        className="error"
        model="login"
        messages={{
          auth: "please check your email and password and try again",
        }}
        wrapper="p"
      />
      <input
        className="primary_button"
        name="signIn"
        type="submit"
        value="Sign In"
      />
      <a onClick={handleResetClick} href="#/">
        Reset Password
      </a>
    </Form>
  );
};

export default LoginForm;
