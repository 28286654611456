import { inline, light, bold } from "components/uikit/utils";
import styled from "styled-components";

export const PLarge = styled.p.attrs({ className: "large" })`
  ${inline}
  ${light}
`;

export const P = styled.p`
  ${inline}
  ${light}
  ${bold}
`;

export const PUppercase = styled.p.attrs({ className: "uppercase" })`
  ${inline}
  ${light}
`;

export const PSmall = styled.p.attrs({ className: "small" })`
  ${inline}
  ${light}
`;

export const PLegal = styled.p.attrs({ className: "legal" })`
  ${inline}
  ${light}
`;

export const MarkTextBanner = styled.mark.attrs({ className: "text_banner" })`
  ${inline}
  ${light}
`;
