export const buildItems = lineItems => {
  return lineItems.map(item => ({
    sku: item.sku,
    delivery_method: item.delivery_method,
    requested_delivery_date: item.requested_delivery_date,
  }));
};

export const buildCustomer = customerInfo => {
  const name = [customerInfo.firstname, customerInfo.lastname]
    .filter(s => s)
    .join(" ");

  const deliveryAddress = customerInfo.line1 && {
    name,
    phone: customerInfo.phone || "",
    line_1: customerInfo.line1 || "",
    line_2: customerInfo.line2 || "",
    city: customerInfo.city || "",
    state: customerInfo.state || "",
    zip: customerInfo.zip || "",
    country: "US",
  };

  return {
    name,
    email: customerInfo.email || "",
    delivery_address: deliveryAddress,
  };
};
