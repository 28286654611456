import * as types from "../actions/types";

const initialState = {
  connectionError: false,
  pageError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ERROR:
      return Object.assign({}, state, {
        [action.error]: true,
      });
    case types.RESET_ERRORS:
      return initialState;
    default:
      return state;
  }
};
