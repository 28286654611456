import { Button } from "components/uikit";
import React, { useEffect, useState } from "react";
import { Control } from "react-redux-form";
import BillingInfo from "./BillingInfo";

const CreditCardFields = ({
  shippingMatchesBilling,
  showShippingMatchesBilling,
  cardNumber,
  cardExpiry,
  cardCvc,
}) => {
  const [cardNumberComplete, setCardNumberComplete] = useState(false);
  const [cardExpiryComplete, setCardExpiryComplete] = useState(false);
  const [cardCvcComplete, setCardCvcComplete] = useState(false);

  const [cardNumberError, setCardNumberError] = useState();
  const [cardExpiryError, setCardExpiryError] = useState();
  const [cardCvcError, setCardCvcError] = useState();

  useEffect(() => {
    cardNumber.mount(`#cardNumber`);
    cardNumber.addEventListener("change", event => {
      setCardNumberError(event.error && event.error.message);
      setCardNumberComplete(event.complete);
    });
  }, [cardNumber]);

  useEffect(() => {
    cardExpiry.mount(`#cardExpiry`);
    cardExpiry.addEventListener("change", event => {
      setCardExpiryError(event.error && event.error.message);
      setCardExpiryComplete(event.complete);
    });
  }, [cardExpiry]);

  useEffect(() => {
    cardCvc.mount(`#cardCvc`);
    cardCvc.addEventListener("change", event => {
      setCardCvcError(event.error && event.error.message);
      setCardCvcComplete(event.complete);
    });
  }, [cardCvc]);

  const enableSubmit =
    cardCvcComplete && cardExpiryComplete && cardNumberComplete;

  return (
    <div id="credit_card_fields">
      <fieldset>
        <dl className="half">
          <dt>
            <label htmlFor="cardNumber">Card Number</label>
          </dt>
          <dd>
            <div id="cardNumber" />
            <div
              data-testid="cardNumber-errors"
              className="errors"
              role="alert"
            >
              {cardNumberError}
            </div>
          </dd>
        </dl>
        <dl className="fourth">
          <dt>
            <label htmlFor="cardExpiry">Exp</label>
          </dt>
          <dd>
            <div id="cardExpiry" />
            <div
              data-testid="cardExpiry-errors"
              className="errors"
              role="alert"
            >
              {cardExpiryError}
            </div>
          </dd>
        </dl>
        <dl id="cvc">
          <dt>
            <label htmlFor="cardCvc">CVV</label>
          </dt>
          <div className="flex_container">
            <dd>
              <div id="cardCvc" />
              <div data-testid="cardCvc-errors" className="errors" role="alert">
                {cardCvcError}
              </div>
            </dd>
            <label className="tooltip_trigger">
              <input type="checkbox" />
              <span>?</span>
              <div className="tooltip cvv">
                <p>
                  The CVV/Security Code is a 3-digit number printed by the
                  signature line on the back of the card; for American Express
                  cards, its the 4-digit number on the front of the card above
                  the account number.
                </p>
                <img src="/images/cvv_example.svg" alt="" />
              </div>
            </label>
          </div>
        </dl>
      </fieldset>
      {showShippingMatchesBilling && (
        <label id="billing_toggle">
          <Control.checkbox
            model=".shippingMatchesBilling"
            name="shippingMatchesBilling"
          />
          {" Billing Address Same As Shipping?"}
        </label>
      )}
      {!shippingMatchesBilling && (
        <BillingInfo shippingMatchesBilling={shippingMatchesBilling} />
      )}

      <Button type="submit" disabled={!enableSubmit}>
        Place Order
      </Button>
    </div>
  );
};

export default CreditCardFields;
