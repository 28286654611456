import { AddressLine1Input } from "components/form";
import { TextInput } from "components/uikit/Form";
import statesByCountry from "data/states";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, Control, Errors } from "react-redux-form";

const COUNTRIES = Object.keys(statesByCountry);

const BillingInfo = ({ shippingMatchesBilling }) => {
  const dispatch = useDispatch();
  const paymentForm = useSelector(state => state.paymentForm);
  const billingCountry = useSelector(state => state.payment.billingCountry);
  const states = statesByCountry[billingCountry] || [];

  const fillAddress = address => {
    dispatch(
      actions.merge("payment", {
        billingLine1: address.line1,
        billingState: address.state,
        billingCity: address.city,
        billingZip: address.zip,
        billingCountry: address.country,
      })
    );
  };

  const addInvalidClass = fieldName =>
    paymentForm[fieldName].valid ? "" : "invalid";

  return (
    <fieldset
      id="billing_info"
      className={shippingMatchesBilling ? "" : "show"}
    >
      <dl className={addInvalidClass("billingName") + " full"}>
        <dt>
          <label htmlFor="billingName">Full Name</label>
        </dt>
        <dd>
          <Control.text
            id="billingName"
            name="billingName"
            model=".billingName"
            component={TextInput}
          />
          <Errors
            className="error"
            model=".billingName"
            messages={{
              required: "Please enter your name.",
            }}
            wrapper="p"
          />
        </dd>
      </dl>
      <dl className={addInvalidClass("billingLine1") + " half"}>
        <dt>
          <label htmlFor="billingLine1">Address</label>
        </dt>
        <dd>
          <AddressLine1Input
            id="billingLine1"
            name="billingLine1"
            onAddressSelected={fillAddress}
          />
          <Errors
            className="error"
            model="payment.billingLine1"
            show={{ touched: true, focus: false }}
            messages={{
              required: "Please enter your street address.",
            }}
            wrapper="p"
          />
        </dd>
      </dl>
      <dl className="half">
        <dt>
          <label htmlFor="billingLine2">Apt/Suite # (optional)</label>
        </dt>
        <dd>
          <Control.text
            id="billingLine2"
            name="billingLine2"
            model=".billingLine2"
            component={TextInput}
          />
        </dd>
      </dl>
      <dl className={addInvalidClass("billingCity") + " half"}>
        <dt>
          <label htmlFor="billingCity">City</label>
        </dt>
        <dd>
          <Control.text
            id="billingCity"
            name="billingCity"
            model=".billingCity"
            component={TextInput}
          />
          <Errors
            className="error"
            model=".billingCity"
            show={{ touched: true, focus: false }}
            messages={{
              required: "Please enter your city.",
            }}
            wrapper="p"
          />
        </dd>
      </dl>
      <dl className={addInvalidClass("billingState") + " sixth"}>
        <dt>
          <label htmlFor="billingState">State</label>
        </dt>
        <dd>
          <div className="select">
            <Control.select
              id="billingState"
              name="billingState"
              model=".billingState"
            >
              <option value="">State</option>
              {states.map(state => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </Control.select>
          </div>
          <Errors
            className="error"
            model=".billingState"
            show={{ touched: true, focus: false }}
            messages={{
              required: "Please enter your state.",
            }}
            wrapper="p"
          />
        </dd>
      </dl>
      <dl className={addInvalidClass("billingZip") + " sixth"}>
        <dt>
          <label htmlFor="billingZip">Zipcode</label>
        </dt>
        <dd>
          <Control.text
            id="billingZip"
            name="billingZip"
            model=".billingZip"
            component={TextInput}
          />
          <Errors
            className="error"
            model=".billingZip"
            show={{ touched: true, focus: false }}
            messages={{
              required: "Please enter your ZIP code.",
            }}
            wrapper="p"
          />
        </dd>
      </dl>
      <dl className={addInvalidClass("billingCountry") + " sixth"}>
        <dt>
          <label htmlFor="billingCountry">Country</label>
        </dt>
        <dd>
          <div className="select">
            <Control.select
              id="billingCountry"
              name="billingCountry"
              model=".billingCountry"
            >
              <option value="">Country</option>
              {COUNTRIES.map(country => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
            </Control.select>
          </div>
          <Errors
            className="error"
            model=".billingCountry"
            show={{ touched: true, focus: false }}
            messages={{
              required: "Please enter your country.",
            }}
            wrapper="p"
          />
        </dd>
      </dl>
    </fieldset>
  );
};

export default BillingInfo;
