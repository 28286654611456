import { useState, useEffect } from "react";

const useDebouncedState = initialValue => {
  const [value, setValue] = useState(initialValue);
  const [debouncedValue, setDebouncedValue] = useState(initialValue);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [value]);

  return [debouncedValue, setValue];
};

export default useDebouncedState;
