import { SET_ZIP, START_OVER } from "actions/types";

const initialState = { zip: "" };

export default (state = initialState, action) => {
  switch (action.type) {
  case SET_ZIP:
    return { ...state, zip: action.zip };
  case START_OVER:
    return initialState;
  default:
    return state;
  }
};
