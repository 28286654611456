export const formatAddress = ({ line1, line2, city, state, zip }) => {
  if (!line1) {
    return null;
  }
  const firstLine = [line1, line2].filter(t => t).join(", ");
  const secondLine = `${city}, ${state} ${zip}`;
  return [firstLine, secondLine].join("\n");
};

export const formatAddressWithName = ({ firstname, lastname, ...address }) => {
  const name = [firstname, lastname].join(" ");
  const formattedAddress = formatAddress(address);
  return [name, formattedAddress].filter(t => t).join("\n");
};

export const displayAddressSecurely = ({ line1 }) =>
  line1.replace(/^\d+\s/, "");
