export const ADD_FLASH_MESSAGE = "ADD_FLASH_MESSAGE";
export const CLEAR_ALL_FLASH_MESSAGES = "CLEAR_ALL_FLASH_MESSAGES";
export const CLEAR_FLASH_MESSAGE = "CLEAR_FLASH_MESSAGE";
export const CLEAR_RECEIPT = "CLEAR_RECEIPT";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const OPEN_MODAL = "OPEN_MODAL";
export const RESET_BREADCRUMB = "RESET_BREADCRUMB";
export const RESET_ERRORS = "RESET_ERRORS";
export const RESET_REGISTER = "RESET_REGISTER";
export const RESET_PROMOTION_KEYS = "RESET_PROMOTION_KEYS";
export const SET_APPLIED_HURDLE = "SET_APPLIED_HURDLE";
export const SET_ERROR = "SET_ERROR";
export const SET_ORDER_ERROR = "SET_ORDER_ERROR";
export const SET_PROMOTION_KEYS = "SET_PROMOTION_KEYS";
export const SET_SAVED_SOURCE_ATTRIBUTION = "SET_SAVED_SOURCE_ATTRIBUTION";
export const SET_SOURCE_ATTRIBUTIONS = "SET_SOURCE_ATTRIBUTIONS";
export const SET_SOURCE_CATEGORY_ID = "SET_SOURCE_CATEGORY_ID";
export const SET_SOURCE_SUBCATEGORY = "SET_SOURCE_SUBCATEGORY";
export const SET_SPEND_HURDLE = "SET_SPEND_HURDLE";
export const SET_STEP_COMPLETED = "SET_STEP_COMPLETED";
export const SET_STEP_NOT_COMPLETED = "SET_STEP_NOT_COMPLETED";
export const SET_STORE_INFO = "SET_STORE_INFO";
export const SET_STORE_RECEIPT = "SET_STORE_RECEIPT";
export const SET_ZIP = "SET_ZIP";
export const START_OVER = "START_OVER";
export const UPDATE_REGISTER = "UWDATE_REGISTER";
