import { modelReducer, formReducer } from "react-redux-form";

const initialState = {
  shippingMatchesBilling: true,
  billingName: "",
  billingLine1: "",
  billingLine2: "",
  billingCity: "",
  billingState: "",
  billingCountry: "US",
  billingZip: "",
};

export const payment = modelReducer("payment", initialState);
export const paymentForm = formReducer("payment", initialState);
