import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { Header, Theme } from "components/uikit";
import { sortProducts } from "helpers/products";
import React from "react";
import styled from "styled-components";
import ProductFamily from "./ProductFamily";

const Container = styled.div`
  padding: 0 2rem;
`;

const Divider = styled.hr`
  border-top: 1px solid ${Theme.COLORS.graphite};
  margin: 0;
  width: 100%;
`;

const Title = styled(Header.H2Display)`
  margin: 2.25rem 2rem 1.75rem;
`;

export const GET_PRODUCTS = gql`
  query GetProducts($parentCategorySlug: String) {
    products: getRetailProducts(parentCategorySlug: $parentCategorySlug) {
      id
      name
      sku
      originalPrice
      color
      size
      weight
      outOfStock
      addons
      physicalHeightInches
      physicalLengthInches
      physicalWidthInches
      shippingHeightInches
      shippingLengthInches
      shippingWeightPounds
      shippingWidthInches
      productFamily {
        id
        fullName
        category {
          slug
          name
        }
      }
    }
  }
`;

const SubCategory = ({ subcategory }) => {
  return (
    <div>
      <Title displayLong data-testid="subcategory-name">
        {subcategory.name}
      </Title>
      {subcategory.productFamilies.map(productFamily => (
        <React.Fragment key={productFamily.fullName}>
          <Divider />
          <ProductFamily productFamily={productFamily} />
        </React.Fragment>
      ))}
      <Divider />
    </div>
  );
};

const CategoryContainer = ({ categorySlug }) => {
  const { data } = useQuery(GET_PRODUCTS, {
    variables: { parentCategorySlug: categorySlug },
  });
  const products = (data && data.products) || [];
  if (products.length <= 0) return null;
  const sortedProducts = sortProducts(products);

  const subcategoriesMap = sortedProducts.reduce((acc, product) => {
    const { productFamily } = product;
    const { category } = productFamily;

    const categoryData = acc[category.slug] || {
      ...category,
      productFamilies: [],
    };

    let existingProductFamily = categoryData.productFamilies.find(
      f => f.id === productFamily.id
    );
    if (!existingProductFamily) {
      existingProductFamily = { ...productFamily, products: [] };
      categoryData.productFamilies.push(existingProductFamily);
    }
    existingProductFamily.products.push(product);

    acc[category.slug] = categoryData;
    return acc;
  }, {});

  return (
    <Container>
      {Object.keys(subcategoriesMap).map(subcategorySlug => (
        <SubCategory
          subcategory={subcategoriesMap[subcategorySlug]}
          key={subcategorySlug}
        />
      ))}
    </Container>
  );
};

export default CategoryContainer;
