import { COLORS, FONTS } from "components/uikit/Theme";
import React from "react";
import styled from "styled-components";
import { ReactComponent as Down } from "svgs/down.svg";

const SelectContainer = styled.div`
  position: relative;
  background: ${COLORS.ultraLightGray};

  select {
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 1rem;
    border: 1px solid ${COLORS.mediumGray};
    border-radius: 0;
    background: transparent;
    font-family: ${FONTS.regular};
    font-size: 1rem;
    appearance: none;
  }

  select:focus {
    outline: solid 0.0625rem ${COLORS.graphite};
  }
`;

const DownContainer = styled.div`
  position: absolute;
  right: 1rem;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;

const Select = ({ options, selected, onChange, placeholder }) => {
  return (
    <SelectContainer>
      <DownContainer>
        <Down />
      </DownContainer>
      <select onChange={e => onChange(e.target.value)} value={selected}>
        {placeholder && <option>{placeholder}</option>}
        {options.map(option => (
          <option
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </option>
        ))}
      </select>
    </SelectContainer>
  );
};

export default Select;
