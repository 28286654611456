import { SET_PROMOTION_KEYS, RESET_PROMOTION_KEYS } from "../actions/types";

const initialState = {
  promos: [],
};

export default (state = initialState, action) => {
  const actions = {
    [SET_PROMOTION_KEYS]: () => {
      return {
        ...state,
        promos: action.promotionKeys,
      };
    },
    [RESET_PROMOTION_KEYS]: () => {
      return initialState;
    },
  };

  if (actions[action.type]) {
    return actions[action.type]();
  }
  return state;
};
