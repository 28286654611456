import { Theme } from "components/uikit";
import styled from "styled-components";

const Divider = styled.hr`
  border-top: 1px solid ${Theme.COLORS.lightGray};
  border-bottom: 0px;
  margin: 1.5rem 0;
  width: 100%;
`;

export default Divider;
