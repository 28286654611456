import { Body, Header, Theme } from "components/uikit";
import { STORE_PICKUP } from "data/deliveryMethods";
import { centToDollar } from "helpers/general";
import React from "react";
import styled from "styled-components";

const hasDiscount = item => {
  return item.original_cost && item.original_cost > item.cost;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.8rem;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.625rem;
`;

const HeaderRow = styled(Row)`
  margin-bottom: 0.875rem;
`;

const OriginalCost = styled.del`
  margin-right: 0.375rem;
`;

const AdditionalInfo = styled(Body.P)`
  color: ${Theme.COLORS.mediumGray};
  margin-top: 0.125rem;
`;

const formatCost = cost => (cost > 0 ? `$${centToDollar(cost)}` : "Free");

const getShippingCostText = deliveryMethod => {
  const { key, shippingCost } = deliveryMethod;
  if (key === STORE_PICKUP) return "";

  return formatCost(shippingCost);
};

const isNonRefundable = item => {
  return item.product_type === "tees";
};

const DeliveryMethodItems = ({ deliveryMethod }) => {
  const { items, name, additionalInfo } = deliveryMethod;
  if (!items || items.length === 0) return null;

  return (
    <Container data-testid="delivery-method-container">
      <HeaderRow data-testid="delivery-method-header">
        <Header.H5>{name}</Header.H5>
        <Body.P>{getShippingCostText(deliveryMethod)}</Body.P>
      </HeaderRow>
      {items.map((item, i) => (
        <Row key={`${item.name}-${i}`} data-testid="delivery-method-item">
          <span>
            <Body.P inline>{item.name}</Body.P>
            {isNonRefundable(item) && (
              <Body.P inline light>
                {" "}
                (non-refundable)
              </Body.P>
            )}
          </span>
          <Body.P>
            {hasDiscount(item) && (
              <OriginalCost>${centToDollar(item.original_cost)}</OriginalCost>
            )}
            ${centToDollar(item.cost)}
          </Body.P>
        </Row>
      ))}
      {additionalInfo && <AdditionalInfo>{additionalInfo}</AdditionalInfo>}
    </Container>
  );
};

export default DeliveryMethodItems;
