import { useQuery } from "@apollo/react-hooks";
import { setSpendHurdle } from "actions/promotions";
import { gql } from "apollo-boost";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { NavLink, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import CategoryContainer from "../CategoryContainer";

export const GET_CATEGORIES = gql`
  query GetCategories {
    categories {
      name
      slug
    }
  }
`;

const Container = styled.main`
  margin-bottom: 6rem;
`;

const CategoriesContainer = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSpendHurdle());
  }, [dispatch]);

  const { data } = useQuery(GET_CATEGORIES);
  if (!data) return null;

  return (
    <Container>
      <nav>
        {data.categories.map(category => (
          <NavLink key={category.slug} to={`/products/${category.slug}`}>
            {category.name}
          </NavLink>
        ))}
      </nav>
      <Switch>
        {data.categories.map(category => (
          <Route
            key={category.slug}
            path={`/products/${category.slug}`}
            render={() => <CategoryContainer categorySlug={category.slug} />}
          />
        ))}
      </Switch>
    </Container>
  );
};

export default CategoriesContainer;
