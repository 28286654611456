import { SET_SPEND_HURDLE, SET_APPLIED_HURDLE } from "../actions/types";

const initialState = {
  hurdles: null,
  appliedHurdle: null,
};

export default (state = initialState, action) => {
  const actions = {
    [SET_SPEND_HURDLE]: () => {
      return {
        ...state,
        hurdles: action.hurdles,
      };
    },
    [SET_APPLIED_HURDLE]: () => {
      return {
        ...state,
        appliedHurdle: action.appliedHurdle,
      };
    },
  };

  if (actions[action.type]) {
    return actions[action.type]();
  }
  return state;
};
