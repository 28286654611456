import moment from "moment-business-days";
import React from "react";

const formatDate = date => moment(date).format("ddd, MMM D");

const StandardDelivery = ({
  estimatedDeliveryRange,
  requestedDeliveryDate,
}) => {
  const dates = (requestedDeliveryDate
    ? [requestedDeliveryDate]
    : estimatedDeliveryRange
  ).filter(date => date);

  const formattedDates = dates.map(formatDate).join(" - ");

  return (
    <div className="description">
      <p>Standard Delivery - Free</p>
      <p className="estimate">
        Estimated delivery date: {formattedDates || "unknown"}
      </p>
    </div>
  );
};

export default StandardDelivery;
