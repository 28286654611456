import { classNames, hideOnPrint } from "components/uikit/utils";
import React from "react";
import styled, { css } from "styled-components";

// We are adding a !important here to overwrite what's being created by the uikit.
// In the original implementation the uikit does not add lateral padding into buttons
// and also set a fixed width of 200px for all buttons what it does not work with large buttons.
const secondary = props =>
  props.secondary &&
  css`
    width: unset !important;
    padding: 0.875rem 1rem !important;
  `;

const wider = props =>
  props.wider &&
  css`
    min-width: 200px;
  `;

const StyledButton = styled.button`
  ${hideOnPrint}
  ${secondary}
  ${wider}
`;

const Button = ({ className, dark, secondary, ...props }) => {
  const classes = classNames(className, {
    dark,
    secondary,
    tn_button: true,
  });
  return <StyledButton {...props} secondary={secondary} className={classes} />;
};

Button.defaultProps = {
  type: "button",
  hideOnPrint: true,
};

export default Button;
