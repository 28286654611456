import { combineReducers } from "redux";
import breadcrumb from "./breadcrumb";
import { cancelOrder, cancelOrderForm } from "./cancelOrder";
import { customerInfo, customerInfoForm } from "./customerInfo";
import errors from "./errors";
import flashMessages from "./flashMessages";
import { lineItems, lineItemsForm } from "./lineItems";
import { login, loginForm } from "./login";
import modals from "./modals";
import order from "./order";
import { payment, paymentForm } from "./payment";
import promotionKeys from "./promotionKey";
import register from "./register";
import sourceAttributions from "./sourceAttributions";
import spendHurdle from "./spendHurdle";
import storeInfo from "./storeInfo";
import zip from "./zip";

export default combineReducers({
  breadcrumb,
  cancelOrder,
  cancelOrderForm,
  customerInfo,
  customerInfoForm,
  errors,
  flashMessages,
  zip,
  lineItems,
  lineItemsForm,
  login,
  loginForm,
  modals,
  order,
  payment,
  paymentForm,
  promotionKeys,
  spendHurdle,
  storeInfo,
  sourceAttributions,
  register,
});
