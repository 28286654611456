import { Body, Header, Link } from "components/uikit";
import { FONTS } from "components/uikit/Theme";
import { hideOnPrint } from "components/uikit/utils";
import { humanize } from "helpers/general";
import moment from "moment";
import React from "react";
import styled from "styled-components";

const TN_EMAIL = "help@tn.com";
const TN_PHONE = "(877) 842-2586";

const Container = styled.div`
  display: flex;
  margin-top: 1.8rem;
`;
const Column = styled.div`
  flex: 1;
  margin-right: 3.3rem;
`;
const Cell = styled.div`
  margin-bottom: 2.25rem;
  ${hideOnPrint}
`;
const Title = styled(Header.H5)`
  margin-bottom: 0.6rem;
`;
const BlockLink = styled(Link)`
  display: inline-block;
  margin-top: 1.3rem;
`;

const BoldSpan = styled.span`
  font-family: ${FONTS.bold};
`;

const formatDate = date => {
  return moment(date).format("ddd, MMM D");
};

const ReceiptActions = props => {
  const {
    receipt,
    handleCancelClick,
    handleSourceAttributionClick,
    selectedSourceName,
  } = props;

  return (
    <Container>
      <Column>
        <Cell data-testid="payment-info">
          <Title>Payment</Title>
          {receipt.card_information && (
            <>
              <Body.PSmall>
                {humanize(receipt.card_information.brand)} Card ending in{" "}
                {receipt.card_information.last_4}
              </Body.PSmall>
              <Body.PSmall>
                Charged on {formatDate(receipt.processed_at)}
              </Body.PSmall>
            </>
          )}
        </Cell>
        <Cell data-testid="cancel-info">
          <Title>Need to cancel?</Title>
          <Body.PSmall>
            This will cancel your whole order. Contact us at
            <BoldSpan> {TN_EMAIL} </BoldSpan>
            or
            <BoldSpan> {TN_PHONE} </BoldSpan>
            if you need a part of your order cancelled.
          </Body.PSmall>
          <BlockLink primary onClick={handleCancelClick}>
            Cancel Order
          </BlockLink>
        </Cell>
      </Column>
      <Column>
        <Cell hideOnPrint>
          <Title>How did you hear about us?</Title>
          <Body.PSmall>
            {selectedSourceName}{" "}
            <Link secondary small onClick={handleSourceAttributionClick}>
              Change
            </Link>
          </Body.PSmall>
        </Cell>
        <Cell data-testid="customer-contact-info">
          <Title>Customer Contact</Title>
          <Body.PSmall>{receipt.name}</Body.PSmall>
          <Body.PSmall>{receipt.email}</Body.PSmall>
          {receipt.phone && <Body.PSmall>{receipt.phone}</Body.PSmall>}
          {receipt.shipping_address1 && (
            <>
              <Body.PSmall>
                {receipt.shipping_address1}
                {receipt.shipping_address2
                  ? `, ${receipt.shipping_address2}`
                  : ""}
              </Body.PSmall>
              <Body.PSmall>
                {receipt.city}, {receipt.address_state} {receipt.zip}
              </Body.PSmall>
              <Body.PSmall>United States</Body.PSmall>
            </>
          )}
        </Cell>
      </Column>
    </Container>
  );
};

export default ReceiptActions;
