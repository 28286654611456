import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Body, Header, Theme } from "components/uikit";
import { formatMoney } from "../../../helpers/general";

const Container = styled.div`
  margin: 1.5rem 0;
`;
const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;
const AddonsLabel = styled(Body.P)`
  display: inline;
  margin-left: 0.5rem;
  ${props => props.disabled && { color: Theme.COLORS.mediumGray }};
`;

const AddonsPrice = styled(Body.P)`
  display: inline;
  float: right;
  ${props => props.disabled && { color: Theme.COLORS.mediumGray }};
`;

const Checkbox = ({
  name,
  label,
  selectedSize,
  defaultAddon,
  outOfStock,
  price,
  setSelectedAddon,
}) => {
  const [checked, setChecked] = useState(false);

  // If a specific size is out-of-stock - uncheck checkbox
  useEffect(() => {
    if (outOfStock) {
      setChecked(false);
      setSelectedAddon(defaultAddon);
    }
  }, [selectedSize, defaultAddon, outOfStock, setSelectedAddon]);

  const handleChange = e => {
    setChecked(e.target.checked);
    setSelectedAddon(e.target.checked ? name : defaultAddon);
  };

  return (
    <>
      <label htmlFor={name}>
        <input
          id={name}
          name={name}
          type="checkbox"
          disabled={outOfStock}
          checked={checked}
          onChange={handleChange}
          data-testid="addon-checkbox"
        />
        <AddonsLabel disabled={outOfStock}>
          {label}
          {outOfStock && " - Sold Out"}
        </AddonsLabel>
      </label>
      <AddonsPrice disabled={outOfStock}>{formatMoney(price)}</AddonsPrice>
    </>
  );
};

const AddonSelector = ({
  selectedSize,
  setSelectedAddon,
  defaultAddon,
  products,
}) => {
  const addonName = products
    .map(item => Object.keys(JSON.parse(item.addons))[0])
    .filter(name => name !== defaultAddon)[0];
  const getProduct = addonKey => {
    return products.find(item => {
      const addons = JSON.parse(item.addons);
      return item.size === selectedSize && Object.prototype.hasOwnProperty.call(addons, addonKey);
    });
  };
  const baseProduct = getProduct(defaultAddon);
  const addonProduct = getProduct(addonName);

  // hide component if addons are not available
  if (!addonProduct) return null;
  const { originalPrice, outOfStock } = addonProduct;

  return (
    <Container data-testid="addon-selector">
      <LabelContainer>
        <Header.H5>Upgrades</Header.H5>
      </LabelContainer>
      <Checkbox
        name={addonName}
        outOfStock={outOfStock}
        label="Add antimicrobial protection by HeiQ"
        price={originalPrice - baseProduct.originalPrice}
        selectedSize={selectedSize}
        setSelectedAddon={setSelectedAddon}
        defaultAddon={defaultAddon}
        onChange={setSelectedAddon}
      />
    </Container>
  );
};

export default AddonSelector;
